import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal, IonTextarea, IonInput, IonDatetime
} from '@ionic/react';

export const POQAdditemModal = (props) => {
    return (

        <IonModal isOpen={props.showModal} onDidDismiss={props.closeModal} className='product-update-modal'>
            <IonCard class="ion-card-scroll">
                <IonCardHeader>
                    <IonCardTitle>Add item PO</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                                <IonRow>
                                    <IonCol size='5'>
                                        <IonLabel className='large-label'><strong>POID</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='7'>
                                        <IonLabel className='large-label'> {props.poid}</IonLabel>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol size='5'>
                                        <IonLabel className='large-label'><strong>SKU</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='7'>
                                        <IonLabel className='large-label'> {props.SupplierSku}</IonLabel>
                                    </IonCol>
                                </IonRow>

                              <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>Qty</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='qtyReceived'
                                        value={props.Qty}
                                        onIonChange={(e) => { props.setQty(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                              </IonRow>

                              <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>store_id</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='storeid'
                                        value={props.storeid}
                                        onIonChange={(e) => { props.setStoreid(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                              </IonRow>

                              <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>user_store_id</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='userstoreid'
                                        value={props.userstoreid}
                                        onIonChange={(e) => { props.setUserstoreid(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                              </IonRow>

                              <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>description</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='description'
                                        value={props.description}
                                        onIonChange={(e) => { props.setDescription(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                              </IonRow>

                              <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>carton_qty</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='cartonqty'
                                        value={props.cartonqty}
                                        onIonChange={(e) => { props.setCartonqty(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                              </IonRow>

                              <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>inner_qty</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='innerqty'
                                        value={props.innerqty}
                                        onIonChange={(e) => { props.setInnerqty(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                              </IonRow>

                            <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>Date Received</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonLabel className='large-label'><strong>{props.dateReceive}</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel> Added By</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel><strong>&nbsp; { props.userName }</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonLabel className='large-label'><strong>Comment</strong></IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonTextarea
                                    className='large-input'
                                    name='comment'
                                    value={props.comment}
                                    onIonChange={(e) => { props.setComment(e.target.value) }}
                                ></IonTextarea>
                            </IonRow>

                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'>
                            <IonButton size='large' onClick={props.onDoneClick}>Done</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='large' onClick={props.closeModal}>Cancel</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
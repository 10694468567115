import fetch from 'isomorphic-fetch';
import { apiUrl } from './../constants';

/**
  Set default fields to make api call.
*/
const defaultOptions = {
    method: "post",
    headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': true // commit this line
    },
    withCredentials: true
};

/**
  Used for making request to the api.
*/
export function makeRequest(uri, options = defaultOptions) {

    if (uri !== apiUrl.userLogin) {
        options.headers['x-tokens'] = localStorage.getItem('token');
    }

    options = JSON.parse(JSON.stringify(options));
    uri = `${apiUrl.baseApiUrl}${uri}`;
    // console.log("uri",uri);
    // console.log("options",options);

    return fetch(uri, options).then(function (response) {
        if (response.status >= 400) {

            //If api call has unauthorised response then redirect to login page.
            if (response.status === 401) {
                let userId = localStorage.getItem('user');
                let userName = localStorage.getItem('userName');
                localStorage.clear();
                localStorage.setItem('user', userId);
                localStorage.setItem('userName', userName);
                window.location.href = '/';
                return;
            }
            //will be throwing some betting structured error messages.
            throw new Error(response.status);
        }
        //returns a json request.
        return response.json();
    });
}

export function makeOdooRequest(uri, options = defaultOptions, method = "Get") {
    var requestOptions = {
      method: method,
      redirect: 'follow'
    };
    console.log("Inside", requestOptions);
    uri = `${apiUrl.baseOdooApiUrl}${uri}`;
    console.log("uri", uri);
    return fetch(uri, requestOptions)
      .then((response) => {
        console.log("Inside res", response);
        return response.json();
      })
      .catch(error => console.log('error', error));
  }

  // export function makeOdooPostRequest(uri, data) {
  //   var requestOptions = {
  //     method: 'POST',
  //     redirect: 'follow'
  //   };
  //   console.log("rQ", requestOptions);
  //   uri = `${apiUrl.baseOdooApiUrl}${uri}?user_api_key=${process.env.ODOO_API_KEY}`;
    
  //   let output = fetch(uri, requestOptions)
  //     .then(response => response.json())
  //     .catch(error => console.log('error', error));
  
  //   console.log("Output1= ", output);
  //   return output;
  // }

  //   //   console.log("Inside",requestOptions);
  //     uri = `${apiUrl.baseOdooApiUrl}${uri}`;
  //   //   console.log("uri",uri);
  //     return fetch(uri, requestOptions)
  //       .then((response) => {
  //           // console.log("Inside res",response);
  //           return response.json()})
  //       //.then(result => console.log(result))
  //       .catch(error => console.log('error', error));
     

    // options.withCredentials = false
    // options = JSON.parse(JSON.stringify(options));
    // console.log("options",options);
    // uri = `${apiUrl.baseOdooApiUrl}${uri}`;
    // console.log("uri",uri);

    // return fetch(uri, options).then(function (response) {
    //     console.log("res",response);
    //     if (response.status >= 400) {

    //         //If api call has unauthorised response then redirect to login page.
    //         if (response.status === 401) {
    //             let userId = localStorage.getItem('user');
    //             let userName = localStorage.getItem('userName');
    //             localStorage.clear();
    //             localStorage.setItem('user', userId);
    //             localStorage.setItem('userName', userName);
    //             window.location.href = '/';
    //             return;
    //         }
    //         //will be throwing some betting structured error messages.
    //         throw new Error(response.status);
    //     }
    //     //returns a json request.
    //     return response.json();
    // })
    // .catch((error) => {
                
    //     //dispatch(setbarcodescan());
    //     console.log("err",error);
    //     // dispatch(stopLoaderAction('getITODescription'));
    //     return false;
    // });
    


export function makeOdooPostRequest(uri,data) {

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    //{"Content-Type":"application/json"}


    var requestOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    redirect: 'follow'
    };
    //requestOptions = JSON.parse(JSON.stringify(requestOptions));
    // console.log("rQ",requestOptions);
    uri = `${apiUrl.baseOdooApiUrl}${uri}`;
    // console.log("odoo uri",uri);
    let output = fetch(uri, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));

    // console.log("Output1= ",output);
    return output

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify(data);

    // var requestOptions = {
    // method: 'POST',
    // headers: myHeaders,
    // body: raw,
    // redirect: 'follow'
    // };
    // uri = `${apiUrl.baseOdooApiUrl}${uri}`;
    // console.log("uri",uri);
    // return fetch(uri, requestOptions)
    // .then(response => response.json())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
}


export function doOdooGetRequest(uri, options = defaultOptions) {
    // if (is_odoo) {
    //     return makeOdooRequest(uri, options, 'get')
    // }
    options.body = null;
    options.method = 'get';
    return makeOdooRequest(uri, options);
}

/**
  Used for making get request to the api.
*/
export function doGetRequest(uri, options = defaultOptions) {
    options.body = null;
    options.method = 'get';
    return makeRequest(uri, options);
}

/**
 Used for making post request to the api.
*/

export function doOdooPostRequest(uri, data, options = defaultOptions) {
    options.method = 'post';
    options.body = JSON.stringify(data);
    return makeOdooPostRequest(uri, options);
}

export function doPostRequest(uri, data, options = defaultOptions) {
    options.method = 'post';

    if (uri === apiUrl.userLogin) {
        options.headers['Authorization'] = 'Basic ' + btoa(data.userId + ":" + data.userPwd);
        options.body = "";
    }
    else {
        options.body = JSON.stringify(data);
    }
    return makeRequest(uri, options);
}

/**
 *
Used for making delete request to the api
 */
export function doDeleteRequest(uri, data, options = defaultOptions) {
    options.body = JSON.stringify(data);
    options.method = 'delete';
    return makeRequest(uri, options);
}

/**
 Used for making put request to the api.
*/
export function doPutRequest(uri, data, options = defaultOptions) {
    options.method = 'put';
    options.body = JSON.stringify(data);
    return makeRequest(uri, options);
}
function initialiseAddToHomeService() {
    window.addEventListener('beforeinstallprompt', function (event) {
        console.log("beforeinstallprompt");
        event.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('App Installed');
            } else {
                console.log('App not installed');
            }
        });
    });
}

export {
    initialiseAddToHomeService
};
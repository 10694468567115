import AWS from 'aws-sdk';

// AWS.config.update({
//     s3BucketEndpoint: true,
//     endpoint : "mate4tech.us-east-1.linodeobjects.com",
//     region: 'us-east-1',
//     accessKeyId: 'U3QC8H7AABVCJ0863ZJY',
//     secretAccessKey: 'EF0SCmHECUYEhbHVB1O2jcT0VuyqC4N5P5kuwjol'
// });

// const S3_BUCKET = 'mate4tech'


AWS.config.update({
    s3BucketEndpoint: true,
    endpoint : "https://aria-images.us-east-1.linodeobjects.com/",
    region: 'us-east-1',
    accessKeyId: 'U3QC8H7AABVCJ0863ZJY',
    secretAccessKey: 'EF0SCmHECUYEhbHVB1O2jcT0VuyqC4N5P5kuwjol'
});

//const S3_BUCKET = 'mate4tech'
const S3_BUCKET = 'aria-images'

export const UploadFilesToS3 = (req) => {
    
    for (let i = 0; i < req.length; i++) {

        let file = req[i];

        let fileParts = file.name.split('.');

        let upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: S3_BUCKET,
                Key: fileParts[0] + '.jpg',
                Body: file,
                ACL: "public-read",
                ContentType: 'jpg'
            }
        });

        var promise = upload.promise();

        promise.then(
            function (data) {
            },
            function (err) {
                console.log(err);
            }
        );
    }
};
import React,{ useState, useEffect }from 'react';
import { IonPage, IonCard, IonCardHeader, IonCardTitle, IonToggle, IonButton,IonInput, IonRow,IonCardContent,IonCol,IonLabel,IonContent,useIonAlert,IonBlur  } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import {
    getPOIDdescriptionAction,AddPoItemsAction,receivePOAction,UpdateQtytoOdooAction
} from './action';
import { config, webUrl } from '../../shared/constants';
import { AppAlert } from '../../components/app-alert';
import { POQuantityModal } from '../../components/po-quantity-modal';
import { POQAdditemModal } from '../../components/po-add-item-modal';
import './index.css';

const ReceivePONewodoo = (props) => {

  const [searchData, setSearchData] = useState({ modal: {} });
  const [toggle, setToggle] = useState(false);
  const [poid, setPoid] = useState(false);
  const [odooid, setOdoo] = useState(false);
  const[supplierSku,setSupplierSku] = useState(false);
  const [alertMessage, setAlertMessage] = useState({show:false,msg:''});
  const [comment, setComment] = useState('');
  const [qtyReceived, setQtyReceived] = useState('');
  const [qtyReceivedAlready, setQtyReceivedAlready] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [addshowModal, setAddshowModal] = useState(false);
  const [qtyOrdered, setQtyOrdered] = useState(false);
  const [qtytoreceive, setQtytoReceive] = useState(false);
  const [Qty,setQty] = useState(false);
  const [dateReceive, setDateReceive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [storeid,setStoreid] = useState(false);
  const [userstoreid,setUserstoreid] = useState(false);
  const [description,setDescription] = useState(false);
  const [cartonqty,setCartonqty] = useState(false);
  const [innerqty,setInnerqty] = useState(false);
  
  var odoo_id  = ""
  const onDoneClick = () => {

    
    
    let _quantityReceived = parseInt(qtyReceivedAlready)
    if(_quantityReceived != -1)
        _quantityReceived = parseInt(qtytoreceive) + parseInt(qtyReceivedAlready || 0);
    else
        _quantityReceived = parseInt(qtytoreceive);

    let _newDic = 
        {
            orderline_id: odooid,
            qty_to_receive: qtytoreceive,

        }
        
    // TODO: update in UI using getelementbyid    

    document.getElementById(supplierSku).innerHTML=_quantityReceived 
    for (var i = 0; i < props.barcodeScan.length; i++) {
      if (props.barcodeScan[i].SupplierSku == supplierSku) {
        props.barcodeScan[i].QtyReceived = _quantityReceived;
        break;
      }
    } 
    //localStorage.setItem('poList', JSON.stringify(_newList || []));
    console.log(_newDic);
    console.log("receive1",_quantityReceived);
    //TODO: hit the api rexreceivepo
    
      receiveItems(_newDic)
      setShowModal(false)
    
    
}
const onReceiveDoneClick = () => {
  let qtyodered = parseInt(Qty)
    let response_data = {
        poid: poid,
        SupplierSku: supplierSku,
        qty: qtyodered,
        date_received: dateReceive,
        added_by: props.userName,
        store_id: storeid,
        user_store_id: userstoreid,
        description: description,
        carton_qty: cartonqty,
        inner_qty: innerqty
    }
    console.log("response",response_data);
    props.AddPoItems(response_data).then((response) => {         //hitting the update api
      if(response){
        setAddshowModal(false)
        response || setAlertMessage({show:true,msg:'Updation failed'})
        setAlertMessage({show:true,msg:'PO sucessfully received',headerText:"Done"})
        console.log("res",response);
      }

    });
    //setAddshowModal(false)
}


    const handleKeyDown = (event,x='')=>{
        event.preventDefault();
        if(x==''){
          var paste_value = event.clipboardData?event.clipboardData.getData("Text"):x;
        }
        else{
          var paste_value = x
        }
        console.log("x",x);
        //console.log("CTRL+V Pressed",event.clipboardData.getData("Text"));
          if (paste_value) {
                 if (!toggle){       //if toggle is off
                    
                    props.getpo(paste_value).then((response) => {
                      console.log("response",response);
                      if(response){
                        setPoid(paste_value)
                        setToggle(!toggle) // toggle on
                      }
                      else{
                        setAlertMessage({show:true,msg:'No po found with id='+paste_value})  //if ito id not found or it's wrong

                      }
                      });
                    }
                  
                  else{                 //if toggle is already switch on
                      var result = props.barcodeScan[0].data.order_lines.find(item => item.barcode === paste_value);
                      console.log("barcode",result.id);
                      //setOdoo(odoo_id)
                      if(result){
                        console.log("result",props.barcodeScan,result);
                        // if(result.QtyReceived>=result.QtyOrdered ){
                        //   setAlertMessage({show:true,msg:'Received qty can\'t be greater than Ordered qty'})
                        // }
                        // else{
  
                          setSupplierSku(paste_value)
                          setQtyOrdered(result.qty_ordered);
                          if(result.qty_to_receive == -1)
                          setQtyReceived(result.qty_ordered);
                          else
                          setQtyReceived(result.qty_ordered - result.qty_to_receive);
                          setQtytoReceive(result.qty_to_receive);
                          setOdoo(result.id)
                          console.log(result.qty_to_receive);
                          setShowModal(true)
                        
  
                      }
                      else{
                        let dt = new Date().toISOString().slice(0, 19).replace('T', ' ');
                        setDateReceive(dt)
                        setAlertMessage({show:true,msg:'The product with SKU='+paste_value+' is not found in this PO. Do you still want to add this',headerText:"Warning",btnCancelText:"No",btnOkText:"Yes",dt:dt})
                        setSupplierSku(paste_value)
                        setPoid(poid)
                      }
                     
                    }
                }
        }
                        
const receiveItems = (data) => {
        console.log("inside receive",data);
        if (data.orderline_id && data.qty_to_receive) {
            props.updateqty(data)
                .then((response) => {
                  console.log("Inside update res",response);

                    //response && clearData();
                    response || setAlertMessage({show:true,msg:'Updation failed'})
                    //response && setAlertMessage({show:true,msg:'PO sucessfully received',headerText:"Done"})
                    setShowAlert(true);
                    //getPOStatusAndItemCount(data.POID);
                });
        }
        else {
            setAlertMessage("No item to receive.");
            setShowAlert(true);
        }
    } 
    
    const clearData = () => {
      setShowModal(false);
      setAddshowModal(false);
      setStoreid("");
      setQty("");
      setInnerqty("");
      setCartonqty("");
      setDescription("");
      setUserstoreid("");
  }

  const closeModal = () => {

    clearData();
}

 const OnValidateClick = (data) => {

       
 }
  

    const barcodeitems = () => {
        //console.log("Barcode scan",props.barcodeScan)
        if (props.barcodeScan) {
          return (
            props.barcodeScan[0].data.order_lines.map((value, index) => {
            odoo_id = value.id
            //console.log("odoo",odoo_id);
            return(
              
              <IonCard className='distribution-card1'>
                <IonRow>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong>{value.barcode}</strong></IonLabel>
                  </IonCol>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong>{value.qty_ordered}</strong></IonLabel>
                  </IonCol>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong><span id={value.barcode}>{value.qty_to_receive != null ? value.qty_to_receive: 0}</span></strong></IonLabel>
                  </IonCol>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong>{value.qty_received}</strong></IonLabel>
                  </IonCol>
                </IonRow>
              </IonCard>             
            );
          })
          );
      }
    }

    const screen1 = () =>{
       return(
        <div class='search-criteria'>
        <h1>Scan PO</h1>
        <IonRow>
        <IonCol sizeSm='4' sizeXs='4' sizeMd='4'>
          <IonLabel className='large-label' >PO Id</IonLabel>
          <IonInput
                className='large-input'
                name='innerqty'
                value={''}
                onIonBlur={(e) => {handleKeyDown(e,e.target.value) }}
                ></IonInput>
        </IonCol>
        </IonRow>
        </div>
       );
    }

    const screen2 = () =>{
      return(
        <IonCard className='distribution-card1'>
              <IonRow>
                <IonCol size="2">
                <IonButton className='button action-btn' type='button' onClick={() => { setToggle(!toggle) }}>
                  Back
                </IonButton>
                </IonCol>
                <IonCol sizeMd="3">
                  <IonLabel className='center'><strong><h1>Poid = {poid}</h1></strong></IonLabel>
                </IonCol>
                {/* <IonCol sizeMd="3">
                  <IonLabel className='center'><strong><h1>id = {odooid}</h1></strong></IonLabel>
                </IonCol> */}
                <IonCol size="2">
                <IonButton className='button action-btn' type='button' onClick={OnValidateClick}>
                  Validate
                </IonButton>
                </IonCol>
              </IonRow>
              <IonCard className='barcode-items'>
              <div>
                  <IonCardHeader>
                    <IonCardTitle>
                        PO Items Receive List 
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent className='barcode-items'>
                    <IonRow>
                      <IonCol sizeMd='3' >
                        <IonLabel className='head-label-small'><strong>SupplierSku</strong></IonLabel>
                      </IonCol>
                      <IonCol sizeMd='3'>
                        <IonLabel className='head-label-small'><strong>QtyOrdered</strong></IonLabel>
                      </IonCol>
                      <IonCol sizeMd='3'>
                        <IonLabel className='head-label-small'><strong>QtytoReceive</strong></IonLabel>
                      </IonCol>
                      <IonCol sizeMd='3'>
                        <IonLabel className='head-label-small'><strong>QtyReceived</strong></IonLabel>
                      </IonCol>
                  </IonRow>
                    {barcodeitems()}
                  </IonCardContent>
                </div>
              </IonCard>
            </IonCard>
      );
    }

    const getDom = () => {
      if(!toggle){
        return screen1()
         
        }
      else{
        return screen2()
      }}
    
         
    return (
        <IonPage onPaste={handleKeyDown} contentEditable={false} >
            <AppHeader headerText='Receive PO Odoo' redirectTo={ props.history.push } />
            
          {/* <div ng-if={statechange}>
              <h1>Scan PO</h1>
          </div> */}
          <div class="pallet-body">
            {getDom()}
          </div>
          <POQuantityModal
                comment={comment}
                qtyReceived={qtytoreceive}
                setComment={setComment}
                setQtyReceived={setQtytoReceive}
                qtyOrdered={qtyOrdered}
                showModal={showModal}
                qtyReceivedAlready={qtyReceivedAlready}
                onDoneClick={onDoneClick}
                closeModal={closeModal}
               />
               {/* onDoneClick={onDoneClick} */}
          <POQAdditemModal
             comment={comment}
             poid={poid}
             showModal={addshowModal}
             SupplierSku ={supplierSku}
             setComment={setComment}
             Qty={Qty}
             storeid={storeid}
             setStoreid={setStoreid}
             userstoreid={userstoreid}
             setUserstoreid={setUserstoreid}
             description={description}
             setDescription={setDescription}
             cartonqty={cartonqty}
             setCartonqty={setCartonqty}
             innerqty={innerqty}
             setInnerqty={setInnerqty}
             userName={props.userName}
             dateReceive={dateReceive}
             setQty={setQty}
             setDateReceive={setDateReceive}
             onDoneClick={onReceiveDoneClick}
             closeModal={closeModal}
          />  
            <AppAlert showAlert={alertMessage.show} headerText={alertMessage.headerText?alertMessage.headerText:"Error"} message={alertMessage.msg} btnCancelText={alertMessage.btnCancelText?alertMessage.btnCancelText:"Ok"} cancelClick={() => { setAlertMessage({show:false,msg:''}) }}
            btnOkText={alertMessage.btnOkText?alertMessage.btnOkText:false} 
            okClick={() => {setAddshowModal(true)}}/>
        </IonPage>

    );
};

const mapStateToProps = state => ({
  barcodeScan: state.receiveodoo.barcodeScan,
  userName: localStorage.getItem('userName')
});

const mapDispatchToProps = dispatch => ({
  getpo: (poId) => dispatch(getPOIDdescriptionAction(poId)),
  AddPoItems: (data) => dispatch(AddPoItemsAction(data)),
  receivePOAction: (data) => dispatch(receivePOAction(data)),
  updateqty: (data) => dispatch(UpdateQtytoOdooAction(data))                 
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReceivePONewodoo));



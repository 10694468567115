import React,{ useState, useEffect }from 'react';
import { IonPage, IonCard, IonCardHeader, IonCardTitle, IonToggle, IonButton,IonInput, IonRow,IonCardContent,IonCol,IonLabel,IonContent,useIonAlert,IonBlur  } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import {
    getPOIDdescriptionAction,AddPoItemsAction,receivePOAction
} from './action';
import { config, webUrl } from '../../shared/constants';
import { AppAlert } from '../../components/app-alert';
import { POQuantityModal } from '../../components/po-quantity-modal';
import { POQAdditemModal } from '../../components/po-add-item-modal';
import './index.css';
import  KeyboardEventHandler  from 'react-keyboard-event-handler';
import BarcodeReader from 'react-barcode-reader';

const ReceiveItotoggle = (props) => {

  const [searchData, setSearchData] = useState({ modal: {} });
  const [toggle, setToggle] = useState(false);
  const [poid, setPoid] = useState(false);
  const[supplierSku,setSupplierSku] = useState(false);
  const [alertMessage, setAlertMessage] = useState({show:false,msg:''});
  const [comment, setComment] = useState('');
  const [qtyReceived, setQtyReceived] = useState('');
  const [qtyReceivedAlready, setQtyReceivedAlready] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [addshowModal, setAddshowModal] = useState(false);
  const [qtyOrdered, setQtyOrdered] = useState(false);
  const [Qty,setQty] = useState(false);
  const [dateReceive, setDateReceive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [storeid,setStoreid] = useState(false);
  const [userstoreid,setUserstoreid] = useState(false);
  const [description,setDescription] = useState(false);
  const [cartonqty,setCartonqty] = useState(false);
  const [innerqty,setInnerqty] = useState(false);
  const [barcodeScan,setBarcodeScan] = useState(false);
  
  const onDoneClick = () => {

    
    
    let _quantityReceived = parseInt(qtyReceivedAlready)
    if(_quantityReceived != -1)
        _quantityReceived = parseInt(qtyReceived) + parseInt(qtyReceivedAlready || 0);
    else
        _quantityReceived = parseInt(qtyReceived);

    let _newDic = 
        {
            poId: poid,
            supplierSku: supplierSku,
            qtyReceived: _quantityReceived,
            qtyOrdered: qtyOrdered,
            comment: comment,
            status: parseInt(qtyOrdered) == _quantityReceived ? 'received-status' : 'variance-status'
        }
        
    // TODO: update in UI using getelementbyid    

    document.getElementById(supplierSku).innerHTML=_quantityReceived 
    for (var i = 0; i < props.barcodeScan.length; i++) {
      if (props.barcodeScan[i].SupplierSku == supplierSku) {
        props.barcodeScan[i].QtyReceived = _quantityReceived;
        break;
      }
    } 
    //localStorage.setItem('poList', JSON.stringify(_newList || []));
    console.log(_newDic);
    console.log("receive1",_quantityReceived);
    //TODO: hit the api rexreceivepo
    
      receiveItems(_newDic)
      setShowModal(false)
    
    
}
const onReceiveDoneClick = () => {
  let qtyodered = parseInt(Qty)
    let response_data = {
        poid: poid,
        SupplierSku: supplierSku,
        qty: qtyodered,
        date_received: dateReceive,
        added_by: props.userName,
        store_id: storeid,
        user_store_id: userstoreid,
        description: description,
        carton_qty: cartonqty,
        inner_qty: innerqty
    }
    console.log("response",response_data);
    props.AddPoItems(response_data).then((response) => {         //hitting the update api
      if(response){
        setAddshowModal(false)
        response || setAlertMessage({show:true,msg:'Updation failed'})
        setAlertMessage({show:true,msg:'PO sucessfully received',headerText:"Done"})
        console.log("res",response);
      }

    });
    //setAddshowModal(false)
}


    const handleKeyDown = (paste_value)=>{
        //event.preventDefault();
        // if(x==''){
        //   var paste_value = event.clipboardData?event.clipboardData.getData("Text"):x;
        // }
        // else{
        //   var paste_value = x
        // }
        // var paste_value = 23545

        console.log("p",paste_value);
        //console.log("x",x);
        //console.log("CTRL+V Pressed",event.clipboardData.getData("Text"));
          if (paste_value) {
                 if (!toggle){       //if toggle is off
                    
                    props.getPoid(paste_value).then((response) => {
                      if(response){
                        setPoid(paste_value)
                        setToggle(!toggle) // toggle on
                      }
                      else{
                        setAlertMessage({show:true,msg:'No po found with id='+paste_value})  //if ito id not found or it's wrong

                      }
                      });
                    }
                  
                  else{                 //if toggle is already switch on
                      var result = props.barcodeScan.find(item => item.SupplierSku === paste_value);
                      if(result){
                        console.log(props.barcodeScan,result);
                        // if(result.QtyReceived>=result.QtyOrdered ){
                        //   setAlertMessage({show:true,msg:'Received qty can\'t be greater than Ordered qty'})
                        // }
                        // else{
  
                          setSupplierSku(paste_value)
                          setQtyOrdered(result.QtyOrdered);
                          if(result.QtyReceived == -1)
                          setQtyReceived(result.QtyOrdered);
                          else
                          setQtyReceived(result.QtyOrdered - result.QtyReceived);
                          setQtyReceivedAlready(result.QtyReceived);
                          console.log(result.QtyReceived);
                          setShowModal(true)
                        
  
                      }
                      else{
                        let dt = new Date().toISOString().slice(0, 19).replace('T', ' ');
                        setDateReceive(dt)
                        setAlertMessage({show:true,msg:'The product with SKU='+paste_value+' is not found in this PO. Do you still want to add this',headerText:"Warning",btnCancelText:"No",btnOkText:"Yes",dt:dt})
                        console.log("R_Pastevalue",paste_value);
                        setSupplierSku(paste_value)
                        setPoid(poid)
                      }
                     
                    }
                }
        }
                        
const receiveItems = (data) => {
        console.log("inside receive",data);
        if (data.poId && data.supplierSku && data.qtyReceived) {
            props.receivePOAction(data)
                .then((response) => {

                    //response && clearData();
                    response || setAlertMessage({show:true,msg:'Updation failed'})
                    //response && setAlertMessage({show:true,msg:'PO sucessfully received',headerText:"Done"})
                    setShowAlert(true);
                    //getPOStatusAndItemCount(data.POID);
                });
        }
        else {
            setAlertMessage("No item to receive.");
            setShowAlert(true);
        }
    } 
    
    const clearData = () => {
      setShowModal(false);
      setAddshowModal(false);
      setStoreid("");
      setQty("");
      setInnerqty("");
      setCartonqty("");
      setDescription("");
      setUserstoreid("");
  }

  const closeModal = () => {

    clearData();
}
  

    const barcodeitems = () => {
        //console.log("Barcode scan",props.barcodeScan)
        if (props.barcodeScan) {
          return (
          props.barcodeScan.map((value, index) => {
            return(

              <IonCard className='distribution-card1'>
                <IonRow>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong>{value.SupplierSku}</strong></IonLabel>
                  </IonCol>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong>{value.QtyOrdered}</strong></IonLabel>
                  </IonCol>
                  <IonCol sizeMd='3'>
                    <IonLabel className='head-label-small'><strong><span id={value.SupplierSku}>{value.QtyReceived != null ? value.QtyReceived : 0}</span></strong></IonLabel>
                  </IonCol>
                </IonRow>
              </IonCard>             
            );
          })
          );
      }
    }

    const screen1 = () =>{
       return(
        <div class='search-criteria'>
        <h1>Scan PO</h1>
        <IonRow>
        <IonCol sizeSm='4' sizeXs='4' sizeMd='4'>
          <IonLabel className='large-label' >PO Id</IonLabel>
          <IonInput
                className='large-input'
                name='innerqty'
                value={''}
                onIonBlur={(e) => {handleinputfield(e,e.target.value) }}
                ></IonInput>
        </IonCol>
        </IonRow>
        </div>
       );
    }

    const screen2 = () =>{
      return(
        <IonCard className='distribution-card1'>
              <IonRow>
                <IonCol size="2">
                <IonButton className='button action-btn' type='button' onClick={() => { setToggle(!toggle) }}>
                  Back
                </IonButton>
                </IonCol>
                <IonCol sizeMd="3">
                  <IonLabel className='center'><strong><h1>Poid = {poid}</h1></strong></IonLabel>
                </IonCol>
              </IonRow>
              <IonCard className='barcode-items'>
              <div>
                  <IonCardHeader>
                    <IonCardTitle>
                        PO Items Receive List 
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent className='barcode-items'>
                    <IonRow>
                      <IonCol sizeMd='3' >
                        <IonLabel className='head-label-small'><strong>SupplierSku</strong></IonLabel>
                      </IonCol>
                      <IonCol sizeMd='3'>
                        <IonLabel className='head-label-small'><strong>QtyOrdered</strong></IonLabel>
                      </IonCol>
                      <IonCol sizeMd='3'>
                        <IonLabel className='head-label-small'><strong>QtyReceived</strong></IonLabel>
                      </IonCol>
                  </IonRow>
                    {barcodeitems()}
                  </IonCardContent>
                </div>
              </IonCard>
            </IonCard>
      );
    }

    const handleBarcodeScan = (data) => {
      console.log("this is pasted data",data);
      handleKeyDown(data)
    };

    const handleinputfield = (event,x) => {
      //console.log("this is pasted data",data);
      //event.preventDefault();
        if(x==''){
          var paste_value = event.clipboardData?event.clipboardData.getData("Text"):x;
        }
        else{
          var paste_value = x
        }
        
      handleKeyDown(paste_value)

    };

    const getDom = () => {
      if(!toggle){
        return screen1()
         
        }
      else{
        return screen2()
      }}
    
         
    return (
        <IonPage
        contentEditable={false}>
          <KeyboardEventHandler handleKeys={['ctrl+enter']} onKeyEvent={handleKeyDown} /> 
            <BarcodeReader onScan={handleBarcodeScan} />
            <AppHeader headerText='Receive PO' redirectTo={ props.history.push } />
            
          {/* <div ng-if={statechange}>
              <h1>Scan PO</h1>
          </div> */}
          {/* onpaste={handleKeyDown} contentEditable={false} */}
          <div class="pallet-body">
            {getDom()}
          </div>
          <POQuantityModal
                comment={comment}
                qtyReceived={qtyReceived}
                setComment={setComment}
                setQtyReceived={setQtyReceived}
                qtyOrdered={qtyOrdered}
                showModal={showModal}
                qtyReceivedAlready={qtyReceivedAlready}
                onDoneClick={onDoneClick}
                closeModal={closeModal}
               />
               {/* onDoneClick={onDoneClick} */}
          <POQAdditemModal
             comment={comment}
             poid={poid}
             showModal={addshowModal}
             SupplierSku ={supplierSku}
             setComment={setComment}
             Qty={Qty}
             storeid={storeid}
             setStoreid={setStoreid}
             userstoreid={userstoreid}
             setUserstoreid={setUserstoreid}
             description={description}
             setDescription={setDescription}
             cartonqty={cartonqty}
             setCartonqty={setCartonqty}
             innerqty={innerqty}
             setInnerqty={setInnerqty}
             userName={props.userName}
             dateReceive={dateReceive}
             setQty={setQty}
             setDateReceive={setDateReceive}
             onDoneClick={onReceiveDoneClick}
             closeModal={closeModal}
          />  
            <AppAlert showAlert={alertMessage.show} headerText={alertMessage.headerText?alertMessage.headerText:"Error"} message={alertMessage.msg} btnCancelText={alertMessage.btnCancelText?alertMessage.btnCancelText:"Ok"} cancelClick={() => { setAlertMessage({show:false,msg:''}) }}
            btnOkText={alertMessage.btnOkText?alertMessage.btnOkText:false} 
            okClick={() => {setAddshowModal(true)}}/>
        </IonPage>

    );
};

const mapStateToProps = state => ({
  barcodeScan: state.itotoggle.barcodeScan,
  userName: localStorage.getItem('userName')
});

const mapDispatchToProps = dispatch => ({
  getPoid: (poid) => dispatch(getPOIDdescriptionAction(poid)),
  AddPoItems: (data) => dispatch(AddPoItemsAction(data)),
  receivePOAction: (data) => dispatch(receivePOAction(data))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReceiveItotoggle));




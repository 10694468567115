import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonLabel,
    IonRow, IonCol, IonContent, IonIcon
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../../components/app-header';
import '../index.css';
import { palletShipperAction } from '../../pallet/action';
import { trashOutline, createOutline } from 'ionicons/icons';
import { MasterModal } from '../../../components/master-modal';
import { AppAlert } from '../../../components/app-alert';
import { addUpdateShipperAction, deleteShipperAction } from '../action';

const ShipperMaster = (props) => {

    const [id, setId] = useState(undefined);
    const [name, setName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        props.palletShipper();
    }, []);

    const onAddClick = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setId(undefined);
        setName('');
    }

    const onDoneClick = () => {

        let request = {
            shipper_name: name
        }

        if (id) {
            request.shipper_id = id
        }

        props.addUpdateShipper(request).then((response) => {

            response && closeModal();
            response && setId(undefined);
            response && setName('');
            response && props.palletShipper();
            response || setAlertMessage("Save failed.");
            response || setShowAlert(true);
        });
    }

    const onDeleteClick = () => {

        props.deleteShipper(id).then((response) => {

            response && closeModal();
            response && setId(undefined);
            response && setName('');
            response && props.palletShipper();
            response || setAlertMessage("Deletion failed.");
            response || setShowAlert(true);
        });
    }

    const cards = () => {

        return (

            props.shipper && Array.isArray(props.shipper) ?
                props.shipper.map((value, index) => {

                    return (
                        <IonCard key={ index }>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel className='head-label'><strong> { value.shipper_id }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol className='card-action'>
                                        {
                                            <IonIcon color='danger' onClick={ (e) => {
                                                e.stopPropagation();
                                                setShowConfirm(true);
                                                setAlertMessage("Are you sure to delete?");
                                                setId(value.shipper_id);
                                            } } icon={ trashOutline } className='header-icon' />
                                        }

                                        {
                                            <IonIcon onClick={ (e) => {
                                                e.stopPropagation();
                                                setShowModal(true);
                                                setId(value.shipper_id);
                                                setName(value.shipper_name);
                                            } } icon={ createOutline } className='header-icon' />
                                        }

                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>Name: <strong>{ value.shipper_name }</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Shipper Master'
                showAdd={ true }
                onAddClick={ onAddClick }
                redirectTo={ props.history.push } />

            <div className="pallet-body">
                <IonCard className='master-body master-height'>
                    <IonContent className='row-data bg-grey'>
                        { cards() }
                    </IonContent>
                </IonCard>
            </div>
            <MasterModal
                name={ name }
                setName={ setName }
                id={ id }
                showModal={ showModal }
                onDoneClick={ onDoneClick }
                closeModal={ closeModal } />

            <AppAlert showAlert={ showConfirm }
                headerText='Confirm'
                message={ alertMessage }
                btnCancelText="Cancel" btnOkText="Yes" okClick={ onDeleteClick }
                cancelClick={ () => { setShowConfirm(false); setId(0); } } />

            <AppAlert showAlert={ showAlert }
                headerText='Message'
                message={ alertMessage } btnCancelText="OK"
                cancelClick={ () => { setShowAlert(false) } } />

        </IonPage >
    );
};

const mapStateToProps = state => ({
    shipper: state.pallet.palletShipper
});

const mapDispatchToProps = dispatch => ({
    palletShipper: () => dispatch(palletShipperAction()),
    deleteShipper: (req) => dispatch(deleteShipperAction(req)),
    addUpdateShipper: (req) => dispatch(addUpdateShipperAction(req))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShipperMaster));

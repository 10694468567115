import React from 'react';
import { IonSelectOption, IonSelect } from '@ionic/react';

export const SingleSelect = (props) => {

    const customPopoverOptions = {
        message: 'Select an option'
    };

    const selectOptions = () => {
        return props.options && props.options.length && props.options.map((itemValue, index) => {
            return <IonSelectOption key={ index } value={ itemValue[props.optionValue] }>
                { itemValue[props.optionName] }
            </IonSelectOption>
        });
    }

    return (
        <IonSelect
            disabled={ props.disabled }
            interface='popover'
            interfaceOptions={ customPopoverOptions }
            name={ props.name }
            value={ props.value }
            onIonChange={ e => props.onChange && props.onChange(props.name, e.detail.value) }>
            { props.options && selectOptions() }
        </IonSelect>
    )
}
import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonLabel,
    IonRow, IonCol, IonContent, IonIcon
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../../components/app-header';
import '../index.css';
import { trashOutline, createOutline } from 'ionicons/icons';
import { AppAlert } from '../../../components/app-alert';
import { deleteUserAction, addUpdateUserAction } from '../action';
import { usersAction, userTypeAction } from '../../user/action';
import { UserMasterModal } from '../../../components/user-modal';

const UserMaster = (props) => {

    const [id, setId] = useState(undefined);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
    const [store, setStore] = useState('');
    const [admin, setAdmin] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        props.appUsers();
        props.userTypes();
    }, []);

    const onAddClick = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        resetModal();
    }

    const resetModal = () => {
        setId(undefined);
        setName('');
        setPassword('');
        setType('');
        setStore('');
        setAdmin(false);
    }

    const setModal = (item) => {
        setId(item.id);
        setName(item.username);
        setPassword(item.password);
        setType(item.user_type);
        setStore(item.store_access_id);
        setAdmin(!!item.is_admin);
    }

    const onDoneClick = () => {

        let request = {
            username: name,
            password: password,
            is_admin: admin ? 1 : 0,
            user_type: type,
            store_access_id: store,
        }

        if (id) {
            request.id = id
        }

        props.addUpdateUser(request).then((response) => {

            response && closeModal();
            response && resetModal();
            response && props.appUsers();
            response || setAlertMessage("Save failed.");
            response || setShowAlert(true);
        });
    }

    const onDeleteClick = () => {

        props.deleteUser(id).then((response) => {

            response && closeModal();
            response && resetModal();
            response && props.appUsers();
            response || setAlertMessage("Deletion failed.");
            response || setShowAlert(true);
        });
    }

    const cards = () => {

        return (

            props.users && Array.isArray(props.users) ?
                props.users.map((value, index) => {

                    return (
                        <IonCard key={index}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel className='head-label'><strong> {value.id}</strong></IonLabel>
                                    </IonCol>

                                    <IonCol className='card-action'>
                                        {
                                            <IonIcon color='danger' onClick={(e) => {
                                                e.stopPropagation();
                                                setShowConfirm(true);
                                                setAlertMessage("Are you sure to delete?");
                                                setId(value.id);
                                            }} icon={trashOutline} className='header-icon' />
                                        }

                                        {
                                            <IonIcon onClick={(e) => {
                                                e.stopPropagation();
                                                setShowModal(true);
                                                setModal(value);
                                            }} icon={createOutline} className='header-icon' />
                                        }

                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='7'>
                                        <IonLabel>User Name: <strong>{value.username}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='5'>
                                        <IonLabel>Store: <strong>{value.store_access_id}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='7'>
                                        <IonLabel>User Type: <strong>{value.user_type}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='5'>
                                        <IonLabel>Admin: <strong>{value.is_admin ? 'Yes' : 'No'}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='User Master'
                showAdd={true}
                onAddClick={onAddClick}
                redirectTo={props.history.push} />

            <div className="pallet-body">
                <IonCard className='master-body master-height'>
                    <IonContent className='row-data bg-grey'>
                        {cards()}
                    </IonContent>
                </IonCard>
            </div>
            <UserMasterModal
                name={name}
                setName={setName}
                id={id}
                password={password}
                setPassword={setPassword}
                type={type}
                setType={setType}
                store={store}
                setStore={setStore}
                admin={admin}
                setAdmin={setAdmin}
                types={props.types}
                showModal={showModal}
                onDoneClick={onDoneClick}
                closeModal={closeModal} />

            <AppAlert showAlert={showConfirm}
                headerText='Confirm'
                message={alertMessage}
                btnCancelText="Cancel" btnOkText="Yes" okClick={onDeleteClick}
                cancelClick={() => { setShowConfirm(false); setId(0); }} />

            <AppAlert showAlert={showAlert}
                headerText='Message'
                message={alertMessage} btnCancelText="OK"
                cancelClick={() => { setShowAlert(false) }} />

        </IonPage >
    );
};

const mapStateToProps = state => ({
    users: state.user.users,
    types: state.user.userTypes
});

const mapDispatchToProps = dispatch => ({
    appUsers: () => dispatch(usersAction()),
    deleteUser: (req) => dispatch(deleteUserAction(req)),
    addUpdateUser: (req) => dispatch(addUpdateUserAction(req)),
    userTypes: () => dispatch(userTypeAction())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMaster));

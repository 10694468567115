import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal, IonTextarea, IonInput
} from '@ionic/react';

export const POQuantityModal = (props) => {
    return (

        <IonModal isOpen={props.showModal}  backdropDismiss={false}  onDidDismiss={props.closeModal} className='product-update-modal'>
            <IonCard class="ion-card-scroll">
                <IonCardHeader>
                    <IonCardTitle>Update Quantity</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            {
                                props.poId &&
                                <IonRow>
                                    <IonCol size='5'>
                                        <IonLabel className='large-label'><strong>POID</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='7'>
                                        <IonLabel className='large-label'> {props.poId}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            }

                            {
                                props.sku &&
                                <IonRow>
                                    <IonCol size='5'>
                                        <IonLabel className='large-label'><strong>SKU</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='7'>
                                        <IonLabel className='large-label'> {props.sku}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            }
                            <IonRow>
                                <IonLabel className='large-label'><strong>Description</strong></IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonLabel className='large-label'> {props.description}</IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>Qty Ordered</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonLabel className='large-label'> {props.qtyOrdered}</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>Qty Received</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonLabel className='large-label'> {props.qtyReceivedAlready}</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='7'>
                                    <IonLabel className='large-label'><strong>Qty Receiving</strong></IonLabel>
                                </IonCol>
                                <IonCol size='5'>
                                    <IonInput
                                        className='large-input'
                                        name='qtyReceived'
                                        value={props.qtyReceived}
                                        onIonChange={(e) => { props.setQtyReceived(e.target.value) }}
                                    >
                                    </IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonLabel className='large-label'><strong>Comment</strong></IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonTextarea
                                    className='large-input'
                                    name='comment'
                                    value={props.comment}
                                    onIonChange={(e) => { props.setComment(e.target.value) }}
                                ></IonTextarea>
                            </IonRow>
                            {/* <IonButton size='large' onClick={props.onDoneClick}>Receive</IonButton> */}
                            {/* <IonButton size='large' onClick={props.closeModal}>Cancel</IonButton> */}
                            </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'>
                            <IonButton size='large' onClick={props.onDoneClick}>Receive</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='large' onClick={props.closeModal}>Cancel</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
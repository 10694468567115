import { getOnlineOrders, getOnlineOrdersCost } from './service';
import { showLoaderAction, stopLoaderAction } from '../user/action';

/**
 * Action types
 */
export const actionTypes = {
    'ORDER_RECEIVED': 'ORDER_RECEIVED',
    'ORDER_UNMOUNT': 'ORDER_UNMOUNT',
    'ORDERS_CLEAR': 'ORDERS_CLEAR',
    'ORDER_COST_RECEIVED': 'ORDER_COST_RECEIVED'
};


export const receivedOrdersAction = (payload) => ({
    type: actionTypes.ORDER_RECEIVED,
    payload
});

export const ordersAction = (data) => {

    return (dispatch, getState) => {

        data && data.page < 2 && dispatch(showLoaderAction('orders'));

        let request = '';

        if (data.startDate) {
            request = request + 'created_at_min=' + data.startDate.substring(0, 10) + 'T00:00:00+10:00&';
        }

        if (data.endDate) {
            request = request + 'created_at_max=' + data.endDate.substring(0, 10) + 'T23:59:59+10:00&';
        }

        request = request + 'page=' + (data.page) + '&financial_status=paid&fields=id,order_number,total_price,processed_at,order_status_url';

        return getOnlineOrders(request)
            .then((response) => {

                data && data.page < 2 && dispatch(stopLoaderAction('orders'));

                response[0] = JSON.parse(response[0]);
                response[1] = JSON.parse(response[1]);

                if (data && data.page > 1) {
                    let state = Object.assign({}, getState());
                    response[0].orders = [...state.order.orders[0].orders, ...response[0].orders];
                }

                dispatch(receivedOrdersAction(response));
                return true;
            })
            .catch((error) => {

                data && data.page < 2 && dispatch(stopLoaderAction('orders'));
                return false;
            });
    }
}

export const unmountOrderAction = () => ({
    type: actionTypes.ORDER_UNMOUNT
});

export const clearOrderAction = () => ({
    type: actionTypes.ORDERS_CLEAR
});

export const receivedOrdersCostAction = (payload) => ({
    type: actionTypes.ORDER_COST_RECEIVED,
    payload
});

export const ordersCostAction = () => {

    return (dispatch) => {

        return getOnlineOrdersCost()
            .then((response) => {

                dispatch(receivedOrdersCostAction(response));
            })
            .catch(() => {

            });
    }
}
import { doPostRequest, doOdooGetRequest,doOdooPostRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';
import { stringformat } from '../../shared/common';

export function getpo(poId) {
    return doOdooGetRequest(stringformat(apiUrl.getpo, [poId]));
}


export function getpodetails(poId) {
    return doOdooGetRequest(stringformat(apiUrl.getpodetails, [poId]));
}
export function getvalidatepo(poId) {
    return doOdooGetRequest(stringformat(apiUrl.validatepo, [poId]));
}

export function AddPoItems(data) {
    return doPostRequest(apiUrl.addPoItems, data);
}

export function updateqty(orderline_id,qty_to_receive) {
    return doOdooPostRequest(stringformat(apiUrl.updateqty, [orderline_id, qty_to_receive]));
}

export function receivePO(data) {
    return doPostRequest(apiUrl.receivePOnew, data);
}
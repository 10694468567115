import {doPostRequest, doGetRequest} from './../../shared/api/base-api';
import {apiUrl} from './../../shared/constants';

//Login api
export function userLogin(data){
   return doPostRequest(apiUrl.userLogin, data);
}

//Api call to logout the user
export function logout(){
   return doGetRequest(apiUrl.userLogout);
}

//Api call to get all users
export function appUsers(){
   return doGetRequest(apiUrl.appUsers);
}

//Api call to get all user types
export function appUserType(){
   return doGetRequest(apiUrl.getUserType);
}


import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal, IonInput, IonCheckbox
} from '@ionic/react';
import { SingleSelect } from '../single-select';
export const UserMasterModal = (props) => {
    return (

        <IonModal isOpen={props.showModal} onDidDismiss={props.closeModal} className='user-master-modal'>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle> {props.id ? 'Update (' + props.id + ')' : 'Add New'}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>User Name</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='name' value={props.name} onIonChange={(e) => { props.setName(e.target.value) }}></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Password</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput type='password' name='pwd' value={props.password} onIonChange={(e) => { props.setPassword(e.target.value) }}></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>User Type</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <SingleSelect
                                        name='type'
                                        options={props.types}
                                        optionValue={'user_type'}
                                        optionName={'user_type'}
                                        value={props.type}
                                        onChange={(name, value) => { props.setType(value) }} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Store</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='store' value={props.store} onIonChange={(e) => { props.setStore(e.target.value) }}></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Is Admin</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonCheckbox className='card-chk' name='admin' checked={props.admin} value={props.admin} onIonChange={e => { props.setAdmin(e.detail.checked) }} />
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={props.onDoneClick}>Done</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={props.closeModal}>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
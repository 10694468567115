import { actionTypes } from './action';

/** Initial state */
const initialState = {
    loading: false,
    data: [],
    error: null,
    showLoader: []
};

/** Reducers */
export default (state = initialState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.USER_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case actionTypes.SHOW_LOADER:
            return {
                ...state,
                showLoader: [...state.showLoader, payload]
            };

        case actionTypes.STOP_LOADER:
            return {
                ...state,
                showLoader: state.showLoader.filter((value) => value !== payload)
            };

        case actionTypes.UNMOUNT_USER:
            return {
                ...initialState
            }

        case actionTypes.USERS_RECEIVED:
            return {
                ...state,
                users: payload
            }

        case actionTypes.USER_TYPE_RECEIVED:
            return {
                ...state,
                userTypes: payload
            }

        default:
            return state;
    }
};

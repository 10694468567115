import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal, IonInput
} from '@ionic/react';
export const DirectToStore = (props) => {
    return (

        <IonModal isOpen={ props.showModal } onDidDismiss={ props.closeModal } className='direct-to-store-modal'>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Direct to Store</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>No. of Pallet</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='noOfPallet'
                                        value={ props.noOfPallet }
                                        onIonChange={ (e) => { props.setNoOfPallet(e.target.value) } }
                                    ></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Supplier</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='supplier'
                                        value={ props.supplier }
                                        onIonChange={ (e) => { props.setSupplier(e.target.value) } }
                                    ></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Con. Number</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='conNumber'
                                        value={ props.conNumber }
                                        onIonChange={ (e) => { props.setConNumber(e.target.value) } }
                                    ></IonInput>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.onDoneClick }>Save</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.closeModal }>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import SignIn from './containers/user';
import Pallet from './containers/pallet';
import AddUpdatePallet from './containers/pallet/add-update';
import Palletnew from './containers/pallet-new';
//import ReceiveItotoggle from './containers/receive-pallet/receive-itotoggle';
//Add new pallet screen
//import RecievePallet from './containers/receive-pallet';
// import AddNewPallet from './containers/add-new-pallet';
// import PalletAddItem from './containers/pallet-add-item';
import PalletBooking from './containers/pallet-booking';
import PalletDispatch from './containers/pallet-dispatch';
import Distribution from './containers/distribution';
import Warehouse from './containers/warehouse';
import Order from './containers/order';
import Product from './containers/product';
import Shipper from './containers/admin/shipper-master';
import PalletType from './containers/admin/pallet-type-master';
import User from './containers/admin/user-master';
import Category from './containers/admin/category-master';
import PurchaseOrder from './containers/purchase-order';
import ReceivePO from './containers/receive-po';
import ReceivePONew from './containers/receive-po-new';
import ReceivePONewodoo from './containers/receive-po-odoo';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global CSS */
import './global.css';
import store from './store';
import { webUrl } from './shared/constants';
import Loader from './components/loader';
import { PrivateRoute } from './private-route';

const App = () => (
  <>
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <Switch>
            <PrivateRoute path={webUrl.category} component={Category} exact={true} />
            <PrivateRoute path={webUrl.user} component={User} exact={true} />
            <PrivateRoute path={webUrl.palletType} component={PalletType} exact={true} />
            <PrivateRoute path={webUrl.shipper} component={Shipper} exact={true} />
            <PrivateRoute path={webUrl.product} component={Product} exact={true} />
            <PrivateRoute path={webUrl.estore} component={Order} exact={true} />
            <PrivateRoute path={webUrl.warehouse} component={Warehouse} exact={true} />
            <PrivateRoute path={webUrl.distribution} component={Distribution} exact={true} />
            <PrivateRoute path={webUrl.addUpdatePallet} component={AddUpdatePallet} exact={true} />
            <PrivateRoute path={webUrl.addUpdatePallet + '/:id'} component={AddUpdatePallet} exact={true}/>
            {/* <PrivateRoute path={webUrl.addNewPallet} component={AddNewPallet} exact={true} />
            <PrivateRoute path={webUrl.palletAddItem} component={PalletAddItem} exact={true} /> */}
            <PrivateRoute path={webUrl.pallet} component={Pallet} exact={true} />
            {/* <PrivateRoute path={webUrl.itotoggle} component={ITOtoggle} exact={true}/> */}
            {/* <PrivateRoute path={webUrl.newpallet} component={NewPallet} exact={true} /> */}
            <PrivateRoute path={webUrl.palletnew} component={Palletnew} exact={true} />
            {/* <PrivateRoute path={webUrl.receivepallet} component={RecievePallet} exact={true} />
            <PrivateRoute path={webUrl.receiveItotoggle} component={ReceiveItotoggle} exact={true} /> */}
            {/* <PrivateRoute path={webUrl.addUpdatePalletNew} component={AddUpdatePalletNew} exact={true} /> */}
            <PrivateRoute path={webUrl.palletBooking} component={PalletBooking} exact={true} />
            <PrivateRoute path={webUrl.palletDispatch} component={PalletDispatch} exact={true} />
            <PrivateRoute path={webUrl.purchaseOrder} component={PurchaseOrder} exact={true} />
            <PrivateRoute path={webUrl.receivePO} component={ReceivePO} exact={true} />
            <PrivateRoute path={webUrl.receivePONew} component={ReceivePONew} exact={true} />
            <PrivateRoute path={webUrl.receivePONewodoo} component={ReceivePONewodoo} exact={true} />
            <Route path={webUrl.signIn} component={SignIn} exact={true} />
            <Route path="/" render={() => <Redirect to={webUrl.signIn} />} exact={true} />
          </Switch>
          <Loader />
        </IonReactRouter>
      </IonApp>
    </Provider>
  </>
);

export default App;
import { doGetRequest, doPostRequest, doPutRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';

export function getProducts(data) {
    return doPostRequest(apiUrl.products, data);
}

export function getProductType() {
    return doGetRequest(apiUrl.productType);
}

export function updateProduct(data) {
    return doPutRequest(apiUrl.updateProduct, data);
}

export function updateProductDescription(data) {
    return doPutRequest(apiUrl.updateProductDescription, data);
}

export function syncProducts(data) {
    return doPutRequest(apiUrl.syncProducts, data);
}
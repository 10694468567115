import { actionTypes } from './action';

/** Initial state */
const initialState = {
    data: [],
    error: null,
};

/** Reducers */
export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.PO_RECEIVED:
            return {
                ...state,
                po: payload,
                error: null
            }

        case actionTypes.PO_ITEMS_RECEIVED:
            return {
                ...state,
                poItems: payload
            }

        case actionTypes.PO_UNMOUNT:
            return {
                ...initialState
            }

        case actionTypes.PO_CLEAR:
            return {
                ...state,
                po: ''
            }

        case actionTypes.PO_STATUS_RECEIVED:
            return {
                ...state,
                poStatus: payload,
                error: null
            };

        case actionTypes.PO_STORE_RECEIVED:
            return {
                ...state,
                poStore: payload,
                error: null
            };

        case actionTypes.PO_QUANTIY_RECEIVED:
            return {
                ...state,
                poQuantity: payload,
                error: null
            };

        default:
            return state;
    }
};

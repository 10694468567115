import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { webUrl } from './shared/constants';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ (props) => (
        localStorage.getItem('isAuthenticated') === "true"
            ? <Component { ...props } />
            : <Redirect to={ webUrl.signIn } />
    ) } />
)
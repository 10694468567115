import { actionTypes } from './action';

/** Initial state */
const initialState = {
    data: [],
    error: null,
    formData: {}
};

/** Reducers */
export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case actionTypes.ORDER_RECEIVED:
            return {
                ...state,
                orders: payload,
                error: null
            }

        case actionTypes.ORDER_UNMOUNT:
            return {
                ...initialState
            }

        case actionTypes.ORDERS_CLEAR:
            return {
                ...state,
                orders: ''
            }

        case actionTypes.ORDER_COST_RECEIVED:
            return {
                ...state,
                ordersCost: payload
            }

        default:
            return state;
    }
};

import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonToggle,
  IonButton,
  IonInput,
  IonRow,
  IonCardContent,
  IonCol,
  IonLabel,
  IonContent,
} from "@ionic/react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppHeader } from "../../components/app-header";
import {
  getITODescriptionAction,
  updateCartonQtyAction,
  palletsAction,
  validateStoreIdAction,
  unmountPalletsAction,
  palletsMasterDataAction,
  syncPriceStatusAction,
  updatePalletFormData,
  receivePOAction,
  getPODescriptionAction,
} from "./action";
import { config, webUrl } from "../../shared/constants";
import { AppAlert } from "../../components/app-alert";
import "./index.css";
import {
  getUtcDateTime,
  hasPermission,
  isValidStatusToChange,
  getUserStore,
  getDayDiff,
  setItemStatusColor,
  convertTZ,
} from "../../shared/common";

const Palletnew = (props) => {
  const [toggle, setToggle] = useState(false);
  const [ito, setIto] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ show: false, msg: "" });
  const [poid, setPoid] = useState(false);
  const [supplierSku, setSupplierSku] = useState(false);
  const [comment, setComment] = useState("");
  const [qtyReceived, setQtyReceived] = useState("");
  const [qtyReceivedAlready, setQtyReceivedAlready] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addshowModal, setAddshowModal] = useState(false);
  const [screen, setScreen] = useState(1);

  const handleKeyDown = (event) => {
    event.preventDefault();
    console.log("CTRL+V Pressed", event.clipboardData.getData("Text"));
    var paste_value = event.clipboardData.getData("Text");
    if (paste_value) {
      if (screen == 3) {
        // scan ito
        props.barcodeScanApi(paste_value).then((response) => {
          if (response) {
            setIto(paste_value);
            setToggle(!toggle);
            setScreen(2);
            // toggle on
          } else {
            setAlertMessage({
              show: true,
              msg: "No ito found with id=" + paste_value,
            }); //if ito id not found or it's wrong
          }
        });
      } 
      else if (screen == 2) {
        // ito barcode scan
        //if toggle is already switch on
        //console.log("Second api hitted",ito,'\n barcode=',itoNumber,'\n barcode=',props.barcodeScan);
        var result = props.barcodeScan.find((item) => item.SKU === paste_value); // fetching the carton_picked from props.barcodeScan
        console.log("result", props.barcodeScan, result.CartonPicked);
        let carton_picked =
          parseInt(result.CartonPicked == null ? 0 : result.CartonPicked) + 1;
        let response_data = {
          CartonPicked: carton_picked,
          ITOID: ito,
          SKU: paste_value,
        };
        console.log("data_", response_data);
        if (carton_picked > result.TotalCarton) {
          setAlertMessage({
            show: true,
            msg: "Picked qty can't be greater than total carton qty",
          });
        } else {
          props.updateQty(response_data).then((response) => {
            //hitting the update api
            if (response) {
              var result_index = props.barcodeScan.findIndex(
                (item) => item.SKU === paste_value
              );
              props.barcodeScan[result_index].CartonPicked = carton_picked;
              document.getElementById(paste_value).innerHTML = carton_picked; //updating the carton_picked qty
            }
          });
        }
      } 
      else if (screen == 4) {
         // po scan
          props.getPoid(paste_value).then((response) => {
            if (response) {
              setPoid(paste_value);
              setToggle(!toggle);
              setScreen(5);
              // toggle on
            } else {
              setAlertMessage({
                show: true,
                msg: "No po found with id=" + paste_value,
              }); //if ito id not found or it's wrong
            }
          });
        
      } 
      else if (screen == 5){
        // po scan
        var result = props.barcodeScanPo.find((item) => item.SupplierSku === paste_value); // fetching the carton_picked from props.barcodeScan
        console.log("result", props.barcodeScanPo);
        let carton_picked =
          parseInt(result.QtyReceived == null ? 0 : result.QtyReceived) + 1;
        let response_data = {
          qtyReceived: carton_picked,
          poId: poid,
          supplierSku: paste_value,
        };
        console.log("data_", response_data);
        // if (carton_picked > result.TotalCarton) {
        //   setAlertMessage({
        //     show: true,
        //     msg: "Picked qty can't be greater than total carton qty",
        //   });}
          
          props.receivePOAction(response_data).then((response) => {
            //hitting the update api
            if (response) {
              var result_index = props.barcodeScanPo.findIndex(
                (item) => item.SupplierSku === paste_value
              );
              props.barcodeScanPo[result_index].QtyReceived = carton_picked;
              document.getElementById(paste_value).innerHTML = carton_picked; //updating the carton_picked qty
            }
          });
      }
      else {
        setAlertMessage("paste value");
      }
    }
  };

  const barcodeitems = () => {
    //console.log("Barcode scan",props.barcodeScan)
    if (props.barcodeScan) {
      return props.barcodeScan.map((value, index) => {
        console.log("Data3", props.barcodeScan);
        return (
          <IonCard>
            <IonRow>
              <IonCol sizeMd="3">
                <IonLabel className="head-label-small">
                  <strong>{value.SKU}</strong>
                </IonLabel>
              </IonCol>
              <IonCol sizeMd="3">
                <IonLabel className="head-label-small">
                  <strong>{value.QtyProposed}</strong>
                </IonLabel>
              </IonCol>
              <IonCol sizeMd="3">
                <IonLabel className="head-label-small">
                  <strong>
                    <span id={value.SKU}>
                      {value.CartonPicked != null ? value.CartonPicked : 0}
                    </span>
                    /{value.TotalCarton}
                  </strong>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonCard>
        );
      });
    }
  };

  const barcodepoitems = () => {
     console.log("Barcode scan",props.barcodeScanPo)
     if (props.barcodeScanPo) {
      return (
      props.barcodeScanPo.map((value, index) => {
        return(

          <IonCard className='distribution-card1'>
            <IonRow>
              <IonCol sizeMd='3'>
                <IonLabel className='head-label-small'><strong>{value.SupplierSku}</strong></IonLabel>
              </IonCol>
              <IonCol sizeMd='3'>
                <IonLabel className='head-label-small'><strong>{value.QtyOrdered}</strong></IonLabel>
              </IonCol>
              <IonCol sizeMd='3'>
                <IonLabel className='head-label-small'><strong><span id={value.SupplierSku}>{value.QtyPicked != null ? value.QtyPicked : 0}</span></strong></IonLabel>
              </IonCol>
            </IonRow>
          </IonCard>             
        );
      })
      );
    }
  };


  const screen1 = () => {
    return (
      <IonRow>
        <IonCol sizeMd="3">
          <IonButton
            className="button action-btn"
            type="button"
            onClick={() => {
              setScreen(4);
            }}
          >
            Scan PO
          </IonButton>
        </IonCol>
        <IonCol sizeMd="3">
          <IonButton
            className="button action-btn"
            type="button"
            onClick={() => {
              setScreen(3);
            }}
          >
            Scan Ito
          </IonButton>
        </IonCol>
      </IonRow>
    );
  };

  const screen2 = () => {
    return (
      <IonCard className="distribution-card1">
        {console.log("Screen 2 ")}
        <IonRow>
          <IonCol size="2">
            <IonButton
              className="button action-btn"
              type="button"
              onClick={() => {
                setScreen(3);
              }}
            >
              Back
            </IonButton>
          </IonCol>
        </IonRow>
        <IonCard className="barcode-items">
          <div>
            <IonCardHeader>
              <IonCardTitle>ITO Items Receive List</IonCardTitle>
            </IonCardHeader>

            <IonCardContent className="barcode-items">
              <IonRow>
                <IonCol sizeMd="3">
                  <IonLabel className="head-label-small">
                    <strong>SupplierSku</strong>
                  </IonLabel>
                </IonCol>
                <IonCol sizeMd="3">
                  <IonLabel className="head-label-small">
                    <strong>QtyOrdered</strong>
                  </IonLabel>
                </IonCol>
                <IonCol sizeMd="3">
                  <IonLabel className="head-label-small">
                    <strong>QtyReceived</strong>
                  </IonLabel>
                </IonCol>
              </IonRow>
              {barcodeitems()}
            </IonCardContent>
          </div>
        </IonCard>
      </IonCard>
    );
  };

  const scan_po_screen = () => {
    return (
      <IonCard>
        <IonRow>
          <IonCol size="2">
            <IonButton
              className="button action-btn"
              type="button"
              onClick={() => {
                setScreen(1);
              }}
            >
              Back
            </IonButton>
          </IonCol>
        </IonRow>
        <div class="screen-po">
          <h1>Scan PO</h1>
        </div>
      </IonCard>
    );
  };

  const scan_ito_screen = () => {
    return (
      <IonCard>
        <IonRow>
          <IonCol size="2">
            <IonButton
              className="button action-btn"
              type="button"
              onClick={() => {
                setScreen(1);
              }}
              >
              Back
            </IonButton>
          </IonCol>
        </IonRow>
        <div class="screen-po">
          <h1>Scan ITO</h1>
        </div>
        {/* {setToggle(toggle)} */}
      </IonCard>
    );
  };

  // const toggleswitch = () =>{

  //     return screen2()

  // }

  const screen5 = () => {
    return (
      <IonCard className="distribution-card1">
        {console.log("Screen 5 ")}
        <IonRow>
          <IonCol size="2">
            <IonButton
              className="button action-btn"
              type="button"
              onClick={() => {
                setScreen(4);
              }}
            >
              Back
            </IonButton>
          </IonCol>
        </IonRow>
        <IonCard className="barcode-items">
          <div>
            <IonCardHeader>
              <IonCardTitle>PO Items Receive List</IonCardTitle>
            </IonCardHeader>

            <IonCardContent className="barcode-items">
              <IonRow>
                <IonCol sizeMd="3">
                  <IonLabel className="head-label-small">
                    <strong>SupplierSku</strong>
                  </IonLabel>
                </IonCol>
                <IonCol sizeMd="3">
                  <IonLabel className="head-label-small">
                    <strong>QtyOrdered</strong>
                  </IonLabel>
                </IonCol>
                <IonCol sizeMd="3">
                  <IonLabel className="head-label-small">
                    <strong>QtyReceived</strong>
                  </IonLabel>
                </IonCol>
              </IonRow>
              {barcodepoitems()}
            </IonCardContent>
          </div>
        </IonCard>
      </IonCard>
    );
  }

  const getDom = () => {
    if (screen == 1) {
      return screen1();
    } else if (screen == 2) {
      return screen2();
    } else if (screen == 3) {
      return scan_ito_screen();
    } else if (screen == 4) {
      return scan_po_screen();
    } else if(screen == 5) {
      return screen5();
    }
  };

  return (
    <IonPage onPaste={handleKeyDown} contentEditable={false}>
      <AppHeader
        headerText="ADD ITEM IN PALLET"
        redirectTo={props.history.push}
      />

      {/* <div ng-if={statechange}>
              <h1>Scan PO</h1>
          </div> */}
      <div class="pallet-body">{getDom()}</div>
      <AppAlert
        showAlert={alertMessage.show}
        headerText={alertMessage.headerText ? alertMessage.headerText : "Error"}
        message={alertMessage.msg}
        btnCancelText={
          alertMessage.btnCancelText ? alertMessage.btnCancelText : "Ok"
        }
        cancelClick={() => {
          setAlertMessage({ show: false, msg: "" });
        }}
        btnOkText={alertMessage.btnOkText ? alertMessage.btnOkText : false}
        okClick={() => {
          setAddshowModal(true);
        }}
      />
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  barcodeScan: state.itotoggle.barcodeScan,
  barcodeScanPo: state.scanpo.barcodeScanPo,
  pallets: state.itotoggle.pallets,
  palletStore: state.itotoggle.palletStore,
  palletCategory: state.itotoggle.palletCategory,
});

const mapDispatchToProps = (dispatch) => ({
  palletsMasterDataAction: () => dispatch(palletsMasterDataAction()),
  barcodeScanApi: (itoNumber) => dispatch(getITODescriptionAction(itoNumber)),
  getPoid: (itoNumber) => dispatch(getPODescriptionAction(itoNumber)),
  palletsAction: (data) => dispatch(palletsAction(data)),
  validateStoreId: (pallet_id, ito_id) => dispatch(validateStoreIdAction(pallet_id, ito_id)),
  syncPriceStatusAction: () => dispatch(syncPriceStatusAction()),
  unmountPalletsAction: () => dispatch(unmountPalletsAction()),
  updateFormData: (data) => dispatch(updatePalletFormData(data)),
  receivePOAction: (data) => dispatch(receivePOAction(data)),
  updateQty: (data) => dispatch(updateCartonQtyAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Palletnew)
);

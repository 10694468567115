import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonInput, IonLabel, IonRow, IonCol,
    IonInfiniteScroll, IonInfiniteScrollContent, IonContent, IonFab, IonFabButton
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import './index.css';
import {
    poAction, unmountPOAction, clearPOAction, poItemsAction, poMasterDataAction, uploadPOAction, receivePOAction
} from './action';
import { SingleSelect } from '../../components/single-select';
import {getUserStore} from '../../shared/common';
import { AppAlert } from '../../components/app-alert';
import { POQuantityModal } from '../../components/po-quantity-modal';
const PurchaseOrder = (props) => {

    const [detailsToShowIndex, setDetailsToShowIndex] = useState(undefined);
    const [itemDetailsToShowIndex, setItemDetailsToShowIndex] = useState(undefined);
    const [pageNo, setPageNo] = useState(1);
    const [searchData, setSearchData] = useState({ modal: {} });
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [comment, setComment] = useState('');
    const [qtyReceived, setQtyReceived] = useState('');
    const [qtyReceivedAlready, setQtyReceivedAlready] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [qtyOrdered, setQtyOrdered] = useState(false);
    const [poId, setPOId] = useState(false);
    const [sku, setSKU] = useState(false);

    useEffect(() => {

        props.poMasterDataAction();
        (props.userStore)&&getPO();

        return () => {
            props.unmountPOAction();
        }
    }, []);

    const getPO = (e) => {

        getPOData(pageNo, e);
    }

    const onFieldChange = (name, value) => {

        setSearchData({
            ...searchData,
            [name]: value
        });
    }

    const getPOData = (page = 1, event) => {

        let request = { page: page };

        if (searchData) {

            if (props.userStore) request.store_id = props.userStore;
            else if (searchData.searchStore) request.store_id = searchData.searchStore;
            if (searchData.searchPOId) request.POid = searchData.searchPOId;
            if (searchData.searchStatus) request.ReceiveStatus_Id = searchData.searchStatus;
            if (searchData.searchSINo) request.SupplierInvoiceNo = searchData.searchSINo;
            if (searchData.barcode) request.SupplierSku = searchData.barcode;
        }

        props.poAction(request).then(() => {

            event && event.target.complete();
        });;

        setPageNo(page + 1);
    }

    const onSearchClick = () => {
        props.clearPOAction();
        setDetailsToShowIndex(undefined);
        setItemDetailsToShowIndex(undefined);
        getPOData(1);
    }

    const onClearClick = () => {

        setSearchData({ modal: {} });
        props.clearPOAction();
        setDetailsToShowIndex(undefined);
        setItemDetailsToShowIndex(undefined);
        setPageNo(1);
    }

    const onRowClick = (index, id, skipIndexing) => {

        if (!skipIndexing && index === detailsToShowIndex) {

            setDetailsToShowIndex(undefined);
        }
        else {

            props.poItemsAction(id).then(() => {

                setDetailsToShowIndex(index);
            });
        }

        setItemDetailsToShowIndex(undefined);
    }

    const onItemRowClick = (index) => {
        index === itemDetailsToShowIndex
            ? setItemDetailsToShowIndex(undefined) :
            setItemDetailsToShowIndex(index);
    }

    const poUploadClick = (e, poId) => {

        e.stopPropagation();
        let data = {
            POID: poId,
            ReceivedBy: localStorage.userName
        }
        props.uploadPOAction(data)
            .then((response) => {

                response && onSearchClick();
                response && setAlertMessage(response);
                response || setAlertMessage("Updation failed.");
                setShowAlert(true);
            });
    }

    const onReceiveClick = (e, item) => {

        e.stopPropagation();
        setShowModal(true);
        setQtyOrdered(item.QtyOrdered);
        setQtyReceived(item.QtyOrdered);
        setQtyReceivedAlready(item.QtyReceived);
        setComment(item.Comments);
        setSKU(item.SupplierSku);
        setPOId(item.POId)
    }

    const closeModal = () => {

        setShowModal(false);
    }

    const onDoneClick = () => {

        let data = {
            POID: poId,
            SupplierSku: sku,
            QuantityReceived: qtyReceived,
            Comment: comment,
            Variance: parseInt(qtyOrdered) - parseInt(qtyReceived),
            ReceivedBy: localStorage.userName
        }

        if (data.POID && data.SupplierSku && data.QuantityReceived) {
            props.receivePOAction(data)
                .then((response) => {

                    response && setShowModal(false);;
                    response || setAlertMessage("Updation failed.");
                    response || setShowAlert(true);
                    response && onRowClick(detailsToShowIndex, data.POID, true);
                });
        }
        else {
            setAlertMessage("No item to receive.");
            setShowAlert(true);
        }
    }

    const setStatus = (value) => {
        if (parseInt(value.QtyOrdered) == parseInt(value.QtyReceived))
            return 'received-status';
        if (parseInt(value.QtyReceived) == 0)
            return 'not-received-status';
        else
            return 'variance-status';
    }

    const poItemsData = () => {
        console.log(props.poItems);
        return (

            props.poItems && props.poItems[0] && props.poItems[0].length > 0 ?
                props.poItems[0].map((value, index) => {

                    return (
                        <IonCard key={index} onClick={(e) => { e.stopPropagation(); onItemRowClick(index) }}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel><strong>{value.SupplierSku}</strong></IonLabel>
                                    </IonCol>
                                    {
                                        <IonCol>
                                            <IonFab><IonFabButton disabled className={'status-fab ' + setStatus(value)}></IonFabButton></IonFab>
                                        </IonCol>
                                    }

                                    {/* <IonCol sizeMd='4' sizeXs='4' >
                                        <IonButton size='small' type='button' onClick={(e) => { onReceiveClick(e, value); }}>
                                            Receive
                                        </IonButton>
                                    </IonCol> */}
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel><strong>{value.description}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs='2' size='2'>
                                        <IonLabel><strong> Qty:</strong></IonLabel>
                                    </IonCol>
                                    <IonCol sizeSm='4'>
                                        <IonLabel>
                                            Ordered <strong>{value.QtyOrdered}</strong>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel>
                                            Received <strong>{value.QtyReceived}</strong>
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>

                                {
                                    index === itemDetailsToShowIndex &&
                                    <>
                                        <IonRow >
                                            <IonCol>
                                                <IonLabel> Product ID <strong>{value.ProductId}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Variance <strong>{value.Variance}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel > Comments <strong>{value.Comments}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received By <strong>{value.ReceivedBy}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    const poData = () => {

        return (

            props.po && props.po[0] && Array.isArray(props.po[0]) ?
                props.po[0].map((value, index) => {

                    return (
                        <IonCard key={index} onClick={() => { onRowClick(index, value.POid); }}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol sizeMd='8'>
                                        <IonLabel className='head-label'><strong> {value.POid}</strong></IonLabel>
                                    </IonCol>
                                    {
                                        value.ReceiveStatus != 'UPLOADED' && value.ReceiveStatus == 'READY' && value.POStatus == 'On-Order' &&
                                        <IonCol sizeMd='4'>
                                            <IonButton size='small' type='button' onClick={(e) => { poUploadClick(e, value.POid); }}>
                                                Upload
                                            </IonButton>
                                        </IonCol>
                                    }
                                    {
                                        value.ReceiveStatus == 'UPLOADED' &&
                                        <IonCol className="margin-top-5">
                                            <IonFab><IonFabButton disabled className={'status-fab'}></IonFabButton></IonFab>
                                        </IonCol>
                                    }

                                    {
                                        value.ReceiveStatus == 'MANUAL' &&
                                        <IonCol className="margin-top-5">
                                            <IonFab><IonFabButton disabled className='status-fab upload-manually'></IonFabButton></IonFab>
                                        </IonCol>
                                    }
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel><strong>{value.POStatus}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel>Last Updated: <strong>{value.LastUpdated && value.LastUpdated.replace('GMT', '')}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol sizeMd='2'>
                                        <IonLabel><strong>{value.store_name}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>

                                {
                                    index === detailsToShowIndex &&
                                    <div>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Container Number <strong>{value.ContainerNumber}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Items <strong>{value.items}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Items Received <strong>{parseInt(value.items) - parseInt(value.QtyNotReceived)}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Items Not Received <strong>{value.QtyNotReceived}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Supplier invoice no. <strong>{value.SupplierInvoiceNo}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Supplier <strong>{value.supplier}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received by <strong>{value.ReceivedBy}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Receive status <strong>{value.ReceiveStatus ? 'Yes' : 'No'}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Special order <strong>{value.SpecialOrder ? 'Yes' : 'No'}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Date created <strong>{value.DateCreated && value.DateCreated.replace('GMT', '')}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Last updated <strong>{value.LastUpdated && value.LastUpdated.replace('GMT', '')}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonCard className='pallet-items'>
                                            <IonCardHeader>
                                                <IonCardTitle>
                                                    PO Items
                                                </IonCardTitle>
                                            </IonCardHeader>

                                            <IonCardContent className='pallet-items'>
                                                {poItemsData()}
                                            </IonCardContent>
                                        </IonCard>
                                    </div>

                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Purchase Order' redirectTo={props.history.push} />

            <div className="pallet-body">

                <IonCard className='search-criteria'>
                    <IonCardHeader className='search-criteria-header'>
                        <IonCardTitle> Search Criteria</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className='search-field-section'>
                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel> PO Id</IonLabel>
                                <IonInput name='searchPOId' value={searchData.searchPOId} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Store</IonLabel>
                                <SingleSelect
                                    name='searchStore'
                                    options={props.poStore}
                                    optionValue='id'
                                    optionName='store_name'
                                    onChange={onFieldChange}
                                    disabled={!!props.userStore}
                                    value={props.userStore || searchData.searchStore} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Status</IonLabel>
                                <SingleSelect
                                    name='searchStatus'
                                    options={props.poStatus}
                                    optionValue={'po_status_id'}
                                    optionName={'po_status_name'}
                                    onChange={onFieldChange}
                                    value={searchData.searchStatus} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel>Supplier invoice no </IonLabel>
                                <IonInput name='searchSINo' value={searchData.searchSINo} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel>Barcode </IonLabel>
                                <IonInput name='barcode' value={searchData.barcode} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                            </IonCol>
                        </IonRow>

                    </IonCardContent>
                    <IonRow>
                        <IonCol sizeMd='3'>
                            <IonButton className='button action-btn' type='button' onClick={onSearchClick}>
                                Search
                            </IonButton>
                        </IonCol>
                        <IonCol sizeMd='3'>
                            <IonButton className='button action-btn' type='button' onClick={onClearClick}>
                                Clear
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCard>

                <IonCard className='search-result pallet-items'>
                    <IonCardHeader className='search-field-section header-caption'>
                        <IonRow className='header-section'>
                            <IonCol sizeMd="7">
                                <IonCardTitle>Search Result</IonCardTitle>
                            </IonCol>
                            <IonCol className='legend-section'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab received-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Received</IonLabel>
                                </IonFab>
                            </IonCol>
                        </IonRow>
                        <IonRow className='legend-section'>
                            <IonCol size='3.5'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab not-received-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Not Received</IonLabel>
                                </IonFab>
                            </IonCol>
                            <IonCol size='3'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab variance-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Variance</IonLabel>
                                </IonFab>
                            </IonCol>
                            <IonCol size='3'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Uploaded</IonLabel>
                                </IonFab>
                            </IonCol>
                            <IonCol size='2.5'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab upload-manually'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Manual</IonLabel>
                                </IonFab>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonCardContent className='search-result-data'>
                        <IonContent>
                            {poData()}
                            <IonInfiniteScroll threshold='100px' onIonInfinite={getPO} disabled={props.po && props.po[0] && Array.isArray(props.po[0]) && props.po[0].length === props.po[1][0].count}>
                                <IonInfiniteScrollContent loadingSpinner='bubbles' loadingText='Loading more data...' />
                            </IonInfiniteScroll>
                        </IonContent>
                    </IonCardContent>
                </IonCard>
            </div>

            <AppAlert showAlert={showAlert}
                headerText='Message'
                message={alertMessage}
                btnCancelText="OK"
                cancelClick={() => { setShowAlert(false) }} />

            <POQuantityModal
                comment={comment}
                qtyReceived={qtyReceived}
                setComment={setComment}
                setQtyReceived={setQtyReceived}
                qtyOrdered={qtyOrdered}
                showModal={showModal}
                qtyReceivedAlready={qtyReceivedAlready}
                onDoneClick={onDoneClick}
                closeModal={closeModal}
                sku={sku}
                poId={poId} />

        </IonPage>
    );
};

const mapStateToProps = state => ({
    po: state.purchaseOrder.po,
    poItems: state.purchaseOrder.poItems,
    poStore: state.purchaseOrder.poStore,
    userStore: getUserStore(),
    poStatus: state.purchaseOrder.poStatus
});

const mapDispatchToProps = dispatch => ({
    poAction: (data) => dispatch(poAction(data)),
    unmountPOAction: () => dispatch(unmountPOAction()),
    clearPOAction: () => dispatch(clearPOAction()),
    poItemsAction: (id) => dispatch(poItemsAction(id)),
    poMasterDataAction: () => dispatch(poMasterDataAction()),
    uploadPOAction: (data) => dispatch(uploadPOAction(data)),
    receivePOAction: (data) => dispatch(receivePOAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder));

import React from 'react';
import { IonAlert } from '@ionic/react';

export const AppAlert = (props) => {

    let alertActions = [
        {
            text: props.btnCancelText,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
                props.cancelClick();
            }
        }
    ]

    if (props.okClick && props.btnOkText)
        alertActions.push(
            {
                text: props.btnOkText,
                handler: () => {
                    props.okClick();
                }
            }
        )

    return (
        <IonAlert
            isOpen={ props.showAlert }
            onDidDismiss={() => props.cancelClick()}
            header={ props.headerText }
            message={ props.message }
            buttons={ alertActions }
        />
    )
}
import { doGetRequest, doPostRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';
import { stringformat } from '../../shared/common';

export function getPoid(poid) {
    return doGetRequest(stringformat(apiUrl.getPoid, [poid]));
}

export function AddPoItems(data) {
    return doPostRequest(apiUrl.addPoItems, data);
}

export function receivePO(data) {
    return doPostRequest(apiUrl.receivePOnew, data);
}
import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal, IonTextarea, IonInput
} from '@ionic/react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const ProductUpdateModal = (props) => {
    return (

        <IonModal isOpen={props.showModal} onDidDismiss={props.closeModal} className='product-update-modal'>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Update Product</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size='3'>
                                    <IonLabel><strong>Description</strong></IonLabel>
                                </IonCol>
                                <IonCol size='9'>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={props.productDescription}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            console.log({ event, editor, data });
                                            props.setProductDescription(data)
                                        }}
                                    />                                  
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='3'>
                                    <IonLabel><strong>SEO Title</strong></IonLabel>
                                </IonCol>
                                <IonCol size='9'>
                                    <IonInput
                                        name='seoTitle'
                                        value={props.seoTitle}
                                        onIonChange={(e) => { props.setSeoTitle(e.target.value) }}
                                    ></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='3'>
                                    <IonLabel><strong>SEO Description</strong></IonLabel>
                                </IonCol>
                                <IonCol size='9'>
                                    <IonTextarea
                                        name='seoDescription'
                                        value={props.seoDescription}
                                        onIonChange={(e) => { props.setSeoDescription(e.target.value) }}
                                    ></IonTextarea>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={props.onDoneClick}>Done</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={props.closeModal}>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
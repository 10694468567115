import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent,
    IonButton, IonInput, IonLabel, IonRow, IonCol,
    IonContent, IonFab, IonFabButton, IonTitle
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import '../purchase-order/index.css';
import {
    unmountPOAction, clearPOAction, poQuantityAction, receivePOAction, uploadPOAction, poStatusAndItemCountAction
} from '../purchase-order/action';
import { POQuantityModal } from '../../components/po-quantity-modal';
import { AppAlert } from '../../components/app-alert';

const ReceivePO = (props) => {

    const [searchData, setSearchData] = useState({ modal: {} });
    const [comment, setComment] = useState('');
    const [qtyReceived, setQtyReceived] = useState('');
    const [qtyReceivedAlready, setQtyReceivedAlready] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [qtyOrdered, setQtyOrdered] = useState(false);
    const [itemDescription, setItemDescription] = useState(false);
    const [poList, setPOList] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [enableUpload, setEnableUpload] = useState(false);
    const [itemCount, setItemCount] = useState('');
    const [poSearchEnable, setPOSearchEnable] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {

        setPOList(JSON.parse(localStorage.getItem('poList')) || []);

        return () => {
            props.unmountPOAction();
        }
    }, []);

    const onFieldChange = (name, value) => {

        setSearchData({
            ...searchData,
            [name]: value
        });
    }

    const onSearchClick = () => {

        if (searchData.searchPOId && searchData.supplierSku) {

            props.poQuantityAction(searchData)
                .then((data) => {

                    if (data) {

                        if (data[0]) {
                            setShowModal(true);
                            setQtyOrdered(data[0].QtyOrdered);
                            setItemDescription(data[0].description)
                            if(data[0].QtyReceived == -1)
                                setQtyReceived(data[0].QtyOrdered);
                            else
                                setQtyReceived(data[0].QtyOrdered - data[0].QtyReceived);
                            setQtyReceivedAlready(data[0].QtyReceived);
                            setComment(data[0].Comments);
                            setPOSearchEnable(false);
                        }
                        else {
                            setAlertMessage("Data not found.");
                            setShowAlert(true);
                        }
                    }
                    else {

                        setAlertMessage("Server error.");
                        setShowAlert(true);
                    }
                });
        }
        else {
            setAlertMessage("POID/SKU should not be empty.");
            setShowAlert(true);
        }
    }

    const receiveItems = (data) => {
        console.log(poList)
        if (data.POID && data.SupplierSku && data.QuantityReceived) {
            props.receivePOAction(data)
                .then((response) => {

                    response && clearData();
                    response || setAlertMessage("Updation failed.");
                    response || setShowAlert(true);
                    getPOStatusAndItemCount(data.POID);
                });
        }
        else {
            setAlertMessage("No item to receive.");
            setShowAlert(true);
        }
    }

    const getPOStatusAndItemCount = (poId) => {
        if (poId) {

            props.poStatusAndItemCountAction(poId)
                .then((response) => {

                    if (response && response[0]) {
                        setEnableUpload(response[0].ReceiveStatus == 'READY' && response[0].POStatus == 'On-Order');
                        setItemCount(response[0].items_count);
                    }
                    else {
                        setAlertMessage("Server Error.");
                        setShowAlert(true);
                    }
                    
                    setTimeout(() => {
                        document.getElementsByName('supplierSku')[1].focus();
                    }, 500);
                });
        }
    }

    const onClearClick = () => {

        setPOList([]);
        clearData();
        props.clearPOAction();
        setSearchData({});
        setEnableUpload(false);
        setItemCount('');
        setPOSearchEnable(true);
        setShowConfirm(false);
        localStorage.setItem('poList', JSON.stringify([]));
    }

    const closeModal = () => {

        clearData();
    }

    const onDoneClick = () => {

        let _polist = poList.filter((value) => {
            return value.poId != searchData.searchPOId
                || value.supplierSku != searchData.supplierSku
        });
        
        let _quantityReceived = parseInt(qtyReceivedAlready)
        if(_quantityReceived != -1)
            _quantityReceived = parseInt(qtyReceived) + parseInt(qtyReceivedAlready || 0);
        else
            _quantityReceived = parseInt(qtyReceived);

        let _newList = [
            {
                poId: searchData.searchPOId,
                supplierSku: searchData.supplierSku,
                qtyReceived: _quantityReceived,
                qtyOrdered: qtyOrdered,
                itemDescription: itemDescription,
                comment: comment,
                status: parseInt(qtyOrdered) == _quantityReceived ? 'received-status' : 'variance-status'
            },
            ..._polist
        ]

        setPOList(_newList);

        receiveItems({
            POID: searchData.searchPOId,
            SupplierSku: searchData.supplierSku,
            QuantityReceived: qtyReceived,
            Comment: comment,
            Variance: parseInt(qtyOrdered) - parseInt(qtyReceived),
            ReceivedBy: localStorage.userName
        });

        localStorage.setItem('poList', JSON.stringify(_newList || []));
    }

    const clearData = () => {
        setShowModal(false);
        setQtyReceived("");
        setComment("");
        setQtyOrdered("");
        setSearchData({ searchPOId: searchData.searchPOId });
    }

    const poUploadClick = () => {

        if (searchData.searchPOId) {
            props.uploadPOAction({ POID: searchData.searchPOId })
                .then((response) => {

                    response && setAlertMessage(response);
                    response || setAlertMessage("Updation failed.");
                    setShowAlert(true);
                });
        }
    }

    const poData = () => {

        return (

            poList.length > 0 ?
                poList.map((value, index) => {

                    return (
                        <IonCard key={index}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>POID <strong>{value.poId}</strong></IonLabel>
                                    </IonCol>

                                    {
                                        value.status &&
                                        <IonCol>
                                            <IonFab><IonFabButton disabled className={'status-fab ' + value.status}></IonFabButton></IonFab>
                                        </IonCol>
                                    }

                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>Product Id <strong>{value.supplierSku}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>Product Description <strong>{value.itemDescription}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel> Qty Ordered <strong>{value.qtyOrdered}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel> Qty Received <strong>{value.qtyReceived}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel> Comments <strong>{value.comment}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Receive PO' redirectTo={props.history.push}
                reload={true} onReloadClick={() => { setShowConfirm(true); }} />

            <div className="pallet-body">

                <IonCard className='search-criteria min-height-30'>
                    <IonRow>
                        <IonCol sizeSm='4' sizeXs='4' sizeMd='4'>
                            <IonLabel className='large-label' >PO Id</IonLabel>
                            <IonInput className='large-input' name='searchPOId' value={searchData.searchPOId}
                                disabled={!poSearchEnable}
                                onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}>
                            </IonInput>
                        </IonCol>
                        <IonCol sizeSm='8' sizeXs='8' sizeMd='4'>
                            <IonLabel className='large-label'>SKU </IonLabel>
                            <IonInput className='large-input' name='supplierSku' value={searchData.supplierSku}
                                onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}
                                type="number">
                            </IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeMd='3'>
                            <IonButton className='button large-button' type='button' onClick={onSearchClick}>
                                Search
                            </IonButton>
                        </IonCol>
                        <IonCol sizeMd='3'>
                            <IonButton className='button large-button' type='button'  onClick={(e) => { poUploadClick(); }}>
                                Upload
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCard>
                {/* disabled={!enableUpload} */}

                <IonCard className='search-result pallet-items height-65'>
                    <IonCardContent className='search-result-data height-97'>
                        <IonContent>
                            {
                                itemCount &&
                                <IonRow>
                                    <IonTitle><label> Items <strong> {poList.length}/{itemCount} </strong> </label></IonTitle>
                                </IonRow>
                            }

                            {poData()}
                        </IonContent>
                    </IonCardContent>
                </IonCard>

            </div>

            <POQuantityModal
                comment={comment}
                description={itemDescription}
                qtyReceived={qtyReceived}
                setComment={setComment}
                setQtyReceived={setQtyReceived}
                qtyOrdered={qtyOrdered}
                showModal={showModal}
                qtyReceivedAlready={qtyReceivedAlready}
                onDoneClick={onDoneClick}
                closeModal={closeModal} />

            <AppAlert showAlert={showAlert}
                headerText='Message'
                message={alertMessage}
                btnCancelText="OK"
                cancelClick={() => { setShowAlert(false) }} />

            <AppAlert showAlert={showConfirm}
                headerText='Confirm' message="Are you sure you want to clear?"
                btnCancelText="Cancel" btnOkText="Yes"
                okClick={onClearClick}
                cancelClick={() => { setShowConfirm(false); }} />
        </IonPage>
    );
};

const mapStateToProps = state => ({
    poQuantity: state.purchaseOrder.poQuantity
});

const mapDispatchToProps = dispatch => ({
    unmountPOAction: () => dispatch(unmountPOAction()),
    clearPOAction: () => dispatch(clearPOAction()),
    poQuantityAction: (data) => dispatch(poQuantityAction(data)),
    receivePOAction: (data) => dispatch(receivePOAction(data)),
    uploadPOAction: (data) => dispatch(uploadPOAction(data)),
    poStatusAndItemCountAction: (data) => dispatch(poStatusAndItemCountAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReceivePO));

import {
    poItems,
    poItemsById,
    poQuantity,
    poStatus,
    poStatusAndItemCount,
    receivePO,
    uploadPO
} from './service';
import { showLoaderAction, stopLoaderAction } from '../user/action';
import { palletStore } from '../pallet/service';

/**
 * Action types
 */
export const actionTypes = {
    'PO_RECEIVED': 'PO_RECEIVED',
    'PO_ITEMS_RECEIVED': 'PO_ITEMS_RECEIVED',
    'PO_UNMOUNT': 'PO_UNMOUNT',
    'PO_CLEAR': 'PO_CLEAR',
    'PO_STATUS_RECEIVED': 'PO_STATUS_RECEIVED',
    'PO_STORE_RECEIVED': 'PO_STORE_RECEIVED',
    'PO_QUANTIY_RECEIVED': 'PO_QUANTIY_RECEIVED'
};

export const receivedPOAction = (payload) => ({
    type: actionTypes.PO_RECEIVED,
    payload
});

export const poAction = (data) => {

    return (dispatch, getState) => {

        data && data.page < 2 && dispatch(showLoaderAction('poItems'));

        return poItems(data)
            .then((response) => {

                data && data.page < 2 && dispatch(stopLoaderAction('poItems'));

                if (data && data.page > 1) {
                    let state = Object.assign({}, getState());
                    response[0] = [...state.purchaseOrder.po[0], ...response[0]];
                }

                dispatch(receivedPOAction(response));
                return true;
            })
            .catch((error) => {

                data && data.page < 2 && dispatch(stopLoaderAction('poItems'));
                return false;
            });
    }
}

export const unmountPOAction = () => ({
    type: actionTypes.PO_UNMOUNT
});

export const clearPOAction = () => ({
    type: actionTypes.PO_CLEAR
});

export const receivedPOItemsAction = (payload) => ({
    type: actionTypes.PO_ITEMS_RECEIVED,
    payload
});

export const poItemsAction = (id) => {

    return (dispatch) => {

        dispatch(showLoaderAction('pOItems'));

        return poItemsById(id)
            .then((response) => {

                dispatch(stopLoaderAction('pOItems'));
                dispatch(receivedPOItemsAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('pOItems'));
            });
    }
}

export const poMasterDataAction = () => {
    return (dispatch) => {
        dispatch(poStatusAction());
        dispatch(poStoreAction());
    }
};

export const receivedPOStatusAction = (payload) => ({
    type: actionTypes.PO_STATUS_RECEIVED,
    payload
});

export const poStatusAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('poStatus'));

        return poStatus()
            .then((response) => {
                dispatch(stopLoaderAction('poStatus'));
                dispatch(receivedPOStatusAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('poStatus'));
            });
    }
}

export const receivedPOStoreAction = (payload) => ({
    type: actionTypes.PO_STORE_RECEIVED,
    payload
});

export const poStoreAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('poStore'));

        return palletStore()
            .then((response) => {
                dispatch(stopLoaderAction('poStore'));
                dispatch(receivedPOStoreAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('poStore'));
            });
    }
}

export const receivedPOQuantityAction = (payload) => ({
    type: actionTypes.PO_QUANTIY_RECEIVED,
    payload
});

export const poQuantityAction = (data) => {

    return (dispatch, getState) => {

        dispatch(showLoaderAction('poQuantity'));

        return poQuantity(data.searchPOId, data.supplierSku)
            .then((response) => {

                dispatch(stopLoaderAction('poQuantity'));
                dispatch(receivedPOQuantityAction(response));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('poQuantity'));
                return false;
            });
    }
}

export const receivePOAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('receivePO'));

        return receivePO(data)
            .then((response) => {

                dispatch(stopLoaderAction('receivePO'));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('receivePO'));
                return false;
            });
    }
}

export const uploadPOAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('uploadPO'));

        return uploadPO(data)
            .then((response) => {

                dispatch(stopLoaderAction('uploadPO'));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('uploadPO'));
                return false;
            });
    }
}

export const poStatusAndItemCountAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('poStatusAndItemCount'));

        return poStatusAndItemCount(data)
            .then((response) => {

                dispatch(stopLoaderAction('poStatusAndItemCount'));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('poStatusAndItemCount'));
                return false;
            });
    }
}
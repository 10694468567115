import { palletStoreAction, palletShipperAction, receivedPalletsAction } from "../pallet/action";
import { palletByStatus, updatePalletShippingStatus } from "../pallet/service";
import { stopLoaderAction, showLoaderAction } from "../user/action";

export const palletBookingMasterDataAction = () => {
    return (dispatch) => {
        dispatch(palletShipperAction());
        dispatch(palletStoreAction());
    }
};

export const palletByStatusAction = (data) => {

    return (dispatch, getState) => {

        data && data.page < 2 && dispatch(showLoaderAction('palletByStatus'));

        return palletByStatus(data)
            .then((response) => {

                data && data.page < 2 && dispatch(stopLoaderAction('palletByStatus'));

                if (data && data.page > 1) {
                    let state = Object.assign({}, getState());
                    response[0] = [...state.pallet.pallets[0], ...response[0]];
                }

                dispatch(receivedPalletsAction(response));
                return true;
            })
            .catch((error) => {

                data && data.page < 2 && dispatch(stopLoaderAction('palletByStatus'));
                return false;
            });
    }
}

export const updatePalletShippingStatusAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('updatePalletShippingStatus'));

        return updatePalletShippingStatus(data)
            .then((response) => {

                dispatch(stopLoaderAction('updatePalletShippingStatus'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('updatePalletShippingStatus'));
                return false;
            });
    }
}
import { showLoaderAction, stopLoaderAction } from '../user/action';
import { addUpdateShipper, deleteShipper, addUpdatePalletType, deletePalletType, addUpdateUsers, deleteUser, addUpdateCategory, deleteCategory } from './service';


export const addUpdateShipperAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('AddUpdateShipper'));

        return addUpdateShipper(req)
            .then((response) => {
                dispatch(stopLoaderAction('AddUpdateShipper'));
                return true;
            })
            .catch(() => {
                dispatch(stopLoaderAction('AddUpdateShipper'));
                return false
            });
    }
}

export const deleteShipperAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('DeleteShipper'));

        return deleteShipper(req)
            .then((response) => {
                dispatch(stopLoaderAction('DeleteShipper'));
                return true;
            })
            .catch((error) => {
                dispatch(stopLoaderAction('DeleteShipper'));
                return false
            });
    }
}


export const addUpdatePalletTypeAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('addUpdatePalletType'));

        return addUpdatePalletType(req)
            .then((response) => {
                dispatch(stopLoaderAction('addUpdatePalletType'));
                return true;
            })
            .catch(() => {
                dispatch(stopLoaderAction('addUpdatePalletType'));
                return false
            });
    }
}

export const deletePalletTypeAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deletePalletType'));

        return deletePalletType(req)
            .then((response) => {
                dispatch(stopLoaderAction('deletePalletType'));
                return true;
            })
            .catch((error) => {
                dispatch(stopLoaderAction('deletePalletType'));
                return false
            });
    }
}

export const addUpdateUserAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('addUpdateUsers'));

        return addUpdateUsers(req)
            .then((response) => {
                dispatch(stopLoaderAction('addUpdateUsers'));
                return true;
            })
            .catch(() => {
                dispatch(stopLoaderAction('addUpdateUsers'));
                return false
            });
    }
}

export const deleteUserAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deleteUser'));

        return deleteUser(req)
            .then((response) => {
                dispatch(stopLoaderAction('deleteUser'));
                return true;
            })
            .catch((error) => {
                dispatch(stopLoaderAction('deleteUser'));
                return false
            });
    }
}

export const addUpdateCategoryAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('addUpdateCategory'));

        return addUpdateCategory(req)
            .then((response) => {
                dispatch(stopLoaderAction('addUpdateCategory'));
                return true;
            })
            .catch(() => {
                dispatch(stopLoaderAction('addUpdateCategory'));
                return false
            });
    }
}

export const deleteCategoryAction = (req) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deleteCategory'));

        return deleteCategory(req)
            .then((response) => {
                dispatch(stopLoaderAction('deleteCategory'));
                return true;
            })
            .catch((error) => {
                dispatch(stopLoaderAction('deleteCategory'));
                return false
            });
    }
}
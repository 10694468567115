import { doGetRequest, doPostRequest, doOdooGetRequest, doOdooPostRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';
import { stringformat } from '../../shared/common';

export function palletStatus() {

    let userType = localStorage.getItem('userType');
    let uri;

    if (userType === 'warehouse')
        uri = apiUrl.palletWarehouseStatus;
    else if (userType === 'store')
        uri = apiUrl.palletStoreStatus;
    else
        uri = apiUrl.palletStatus;

    return doGetRequest(uri);
}

export function palletCategory() {
    return doGetRequest(apiUrl.palletCategory);
}

export function palletStore() {
    return doGetRequest(apiUrl.palletStore);
}

export function pallets(data) {
    return doPostRequest(apiUrl.pallets, data);
}

export function palletItemsById(id) {
    return doGetRequest(stringformat(apiUrl.palletItemsById, [id]));
}

export function palletTypes() {
    return doGetRequest(apiUrl.palletTypes);
}

export function palletBuilders() {
    return doGetRequest(apiUrl.palletBuilders);
}

export function savePallet(data) {
    return doPostRequest(apiUrl.addPalletAndItems, data);
}

export function updatePallet(data) {
    return doPostRequest(apiUrl.addPalletAndItems, data);
}

export function savePalletItem(data) {
    return doPostRequest(apiUrl.addPalletItem, data);
}

export function deletePallet(id) {
    return doGetRequest(stringformat(apiUrl.deletePallet, [id]));
}

export function deletePalletItem(id) {
    return doGetRequest(stringformat(apiUrl.deletePalletItem, [id]));
}

export function updatePalletItem(data) {
    return doPostRequest(apiUrl.updatePalletItem, data);
}

export function palletShipper() {
    return doGetRequest(apiUrl.palletShipper);
}

export function palletByStatus(data) {
    return doPostRequest(apiUrl.palletByStatus, data);
}

export function updatePalletShippingStatus(data) {
    return doPostRequest(apiUrl.updatePalletShippingStatus, data);
}

export function getItemDescription(data) {
    return doOdooGetRequest(stringformat(apiUrl.getDescription, [data]));
}
// add new distribution module

export function getDIDdescription(did_reference, barcode) {
    const url = stringformat(apiUrl.getDIDdescription, [did_reference, barcode]);
    return doOdooGetRequest(url);
}

export function getITOdescription_new(ito_reference, barcode) {
    const url = stringformat(apiUrl.getITOdescription_new, [ito_reference, barcode]);
    return doOdooGetRequest(url);
}


// add new start
export function getdidnumbervalid(did_number,store_id) {
    return doOdooGetRequest(stringformat(apiUrl.getDiDnumbervalid, [did_number,store_id]));
}

export function getitonumbervalid(ito_number) {
    return doOdooGetRequest(stringformat(apiUrl.getITOnumbervalid, [ito_number]));
}

export function getstorepo(store_id) {
    return doOdooGetRequest(stringformat(apiUrl.getStorePO, [store_id]));
}
// ends api


export function getProdcutQuanityDetails(data) {
    return doOdooGetRequest(stringformat(apiUrl.getProductQuantityDetails, [data]));
}

export function updateOdooPalletData(data) {
    return doOdooPostRequest(apiUrl.updatePalletOdoo, data);
}

export function getITODetails(data) {
    return doPostRequest(apiUrl.pallets, data);
}

export function syncPrice() {
    return doPostRequest(apiUrl.syncPrice, {});
}

export function syncPriceStatus() {
    return doGetRequest(apiUrl.syncPriceStatus);
}
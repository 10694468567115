import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import userReducer from './containers/user/reducer';
import palletReducer from './containers/pallet/reducer';
import orderReducer from './containers/order/reducer';
import productReducer from './containers/product/reducer';
import purchaseOrderReducer from './containers/purchase-order/reducer';
import itotoggleReducer from './containers/receive-po-new/reducer';
import receiveodooReducer from './containers/receive-po-odoo/reducer';
import scanpoReducer from './containers/pallet-new/reducer'

const rootReducers = combineReducers({
    user: userReducer,
    pallet: palletReducer,
    order: orderReducer,
    product : productReducer,
    purchaseOrder : purchaseOrderReducer,
    itotoggle: itotoggleReducer,
    receiveodoo: receiveodooReducer,
    scanpo: scanpoReducer
    
});

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducers,
    composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
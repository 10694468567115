import React, { useState, useEffect } from 'react';
import { IonPage, IonItem, IonButton, IonInput, IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import './index.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLoginAction, unmountUserAction } from './action';
import { messages, webUrl } from '../../shared/constants';
import { initialiseAddToHomeService } from '../../components/add-to-home';

const SignIn = (props) => {

    const [userData, setuserData] = useState({ redirect: props.history.push });
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {

        let user = localStorage.getItem('user');
        let name = localStorage.getItem('userName');

        setuserData({
            ...userData,
            userId: user,
            userName: name
        });
      
        localStorage.clear();
        props.unmountUser();
        initialiseAddToHomeService();

    }, []);

    const onLogin = () => {

        if (userData && userData.userId && userData.userPwd && userData.userName) {

            props.userLogin(userData)
                .then((response) => {
                    response || setErrorMessage("Please enter valid credentials.");
                    response && props.history.push(webUrl.pallet);
                });
        }
        else {
            setErrorMessage(messages.requiredSignInField);
        }
    }

    const onFieldChange = (e) => {

        if (!e || !e.target)
            return;

        if (errorMessage)
            setErrorMessage('');

        let name = e.target.name;
        let value = e.target.value;

        setuserData({
            ...userData,
            [name]: value
        });
    }

    const onFieldKeyUp = (e) => {
        if (e.keyCode === 13)
            onLogin();
    }

    return (
        <IonPage className="sign-in-body">
            <div className="monty-container">
                <div className="monty"></div>
            </div>
            <div className="sign-in-container">
                
                <div className="logo"></div>

                <div className='head-section'>
                    <h5 className='header'>ARIA Pallet Tracker</h5>
                    <h5 className='sub-header'>Sign in to your account</h5>
                </div>

                <div className='sign-in-control'>
                    <IonItem>
                        <IonInput placeholder="User Id" name='userId' value={ userData.userId } onKeyUp={ onFieldKeyUp } onIonChange={ onFieldChange } ></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonInput placeholder="Password" type='password' value={ userData.userPwd } name='userPwd' onKeyUp={ onFieldKeyUp } onIonChange={ onFieldChange }></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonInput placeholder="User Name" name='userName' value={ userData.userName } onKeyUp={ onFieldKeyUp } onIonChange={ onFieldChange }></IonInput>
                    </IonItem>
                </div>

                <div className='sign-in-actions'>
                    {
                        errorMessage &&
                        <p className='error'>
                            <IonIcon icon={ alertCircleOutline } className='error-icon' />
                            { errorMessage }
                        </p>
                    }

                    <IonButton className='button' type='button' onClick={ onLogin }>
                        Login
                    </IonButton>
                </div>
            </div>

        </IonPage>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    userLogin: (userData) => dispatch(userLoginAction(userData)),
    unmountUser: () => dispatch(unmountUserAction())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));

import { userLogin, appUsers, appUserType } from './service';

/**
 * Action types
 */
export const actionTypes = {
    'USER_LOGIN_REQUEST': 'USER_LOGIN_REQUEST',
    'USER_LOGIN_REQUEST_SUCCESS': 'USER_LOGIN_REQUEST_SUCCESS',
    'SHOW_LOADER': 'SHOW_LOADER',
    'STOP_LOADER': 'STOP_LOADER',
    'UNMOUNT_USER': 'UNMOUNT_USER',
    'USERS_RECEIVED': 'USERS_RECEIVED',
    'USER_TYPE_RECEIVED': 'USER_TYPE_RECEIVED'
};

export const requestUserLoginAction = () => ({
    type: actionTypes.USER_LOGIN_REQUEST
});

export const receivedHuntingAreaAction = (payload) => ({
    type: actionTypes.USER_LOGIN_REQUEST_SUCCESS,
    payload
});

export const showLoaderAction = (payload) => ({
    type: actionTypes.SHOW_LOADER,
    payload
});

export const stopLoaderAction = (payload) => ({
    type: actionTypes.STOP_LOADER,
    payload
});


export const userLoginAction = (userData) => {

    return (dispatch) => {

        dispatch(showLoaderAction('userLogin'));

        return userLogin(userData)
            .then((response) => {

                dispatch(stopLoaderAction('userLogin'));

                if (response && response[0] && response[0].token) {
                    localStorage.setItem('user', userData.userId);
                    localStorage.setItem('userName', userData.userName);
                    localStorage.setItem('token', response[0].token);
                    localStorage.setItem('isAdmin', response[1].isAdmin);
                    localStorage.setItem('isAuthenticated', true);
                    localStorage.setItem('userType', response[2].user_type);
                    localStorage.setItem('store', response[3].store_access_id);
                    return true;
                }
                else {
                    return false;
                }
            })
            .catch((error) => {
                dispatch(stopLoaderAction('userLogin'));
                return false;
            });
    }
}

export const unmountUserAction = () => ({
    type: actionTypes.UNMOUNT_USER
});

export const receivedUserAction = (payload) => ({
    type: actionTypes.USERS_RECEIVED,
    payload
});

export const usersAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('appUsers'));

        return appUsers()
            .then((response) => {

                dispatch(stopLoaderAction('appUsers'));
                dispatch(receivedUserAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('appUsers'));
            });
    }
}

export const receivedUserTypeAction = (payload) => ({
    type: actionTypes.USER_TYPE_RECEIVED,
    payload
});

export const userTypeAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('appUserType'));

        return appUserType()
            .then((response) => {

                dispatch(stopLoaderAction('appUserType'));
                dispatch(receivedUserTypeAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('appUserType'));
            });
    }
}
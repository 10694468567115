import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonButton, IonLabel, IonRow, IonCol,
    IonInfiniteScroll, IonInfiniteScrollContent, IonContent, IonCardHeader, IonCardTitle, IonInput, IonImg, IonFab, IonFabButton, IonIcon, IonCheckbox
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import { webUrl } from '../../shared/constants';
import './index.css';
import { productAction, unmountProductAction, productTypeAction, clearProductFormData, updateProductFormData, clearProductAction, updateProductAction, updateProductDescriptionAction, syncProductsAction } from './action';
import { SingleSelect } from '../../components/single-select';
import { hasPermission } from '../../shared/common';
import { flashOutline, flashOffOutline, createOutline, caretBackOutline, caretForwardOutline } from 'ionicons/icons';
import { AppAlert } from '../../components/app-alert';
import { UploadFilesToS3 } from './file-uploader';
import { ProductUpdateModal } from '../../components/product-update-modal';

const Product = (props) => {

    const [pageNo, setPageNo] = useState(1);
    const [detailsToShowIndex, setDetailsToShowIndex] = useState(undefined);
    const [showConfirm, setShowConfirm] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [productId, setProductId] = useState(0);
    const [uploadPic, setuploadPic] = useState(null);
    const [productDescription, setProductDescription] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [productToSync, setProductToSync] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);


    useEffect(() => {

        getProducts();
        props.productTypeAction();

        return () => {

            props.unmountProductAction();
        }
    }, []);

    const onRowClick = (index) => {

        if (index === detailsToShowIndex) {

            setDetailsToShowIndex(undefined);
        }
        else {
            setDetailsToShowIndex(index);
        }
    }

    const getProducts = (e) => {

        getProductData(pageNo, e);
    }

    const onFieldChange = (name, value) => {

        props.updateProductFormData({ [name]: value });
    }

    const getProductData = (page = 1, event) => {

        let request = { page: page, ...props.formData };

        props.productAction(request).then(() => {

            event && event.target.complete();
            setIsAllChecked(false);
            setProductToSync([]);
        });;

        setPageNo(page + 1);
    }

    const onSearchClick = () => {

        props.clearProductAction();
        getProductData(1);
    }

    const onClearClick = () => {
        props.clearProductAction();
        props.clearProductFormData();
        setPageNo(1);
    }

    const setStatusColor = (value) => {

        if (value && value.published_at) {
            return 'published-status';

        }
        else {
            return 'un-published-status';
        }
    }

    const publishProduct = (value) => {

        updateProduct({
            ids: [value],
            published_status: true
        });
    }

    const unPublish = () => {

        setShowConfirm(false);

        updateProduct({
            ids: [productId],
            published_status: false
        });
    }

    const updateProduct = (data) => {

        props.updateProduct(data)
            .then((response) => {

                response && setProductId(0);
                response && setProductToSync([]);
                response && onSearchClick();
                response || setAlertMessage("Updation failed.");
                response || setShowAlert(true);
            });
    }

    const handleFileUpload = (e) => {
        e.preventDefault();
        let _files = e.target.files;

        if (!_files) {
            return;
        }

        UploadFilesToS3(_files);
    }

    const handleAddPhotoClick = (e) => {
        uploadPic.click()
    }

    const closeModal = () => {
        setShowUpdateModal(false);
        setProductId(0);
        setProductDescription('');
        setSeoTitle('');
        setSeoDescription('');
    }

    const onDoneClick = () => {

        let request = {
            id: productId.toString(),
            description: productDescription,
            seo_title: seoTitle,
            seo_description: seoDescription
        }

        props.updateProductDescription(request).then((response) => {

            response && closeModal();
            response && setProductId(0);
            response && setProductDescription('');
            response && setSeoTitle('');
            response && setSeoDescription('');
            response && onSearchClick();
            response || setAlertMessage("Updation failed.");
            response || setShowAlert(true);
        });
    }

    const onProductChecked = (product, checked) => {

        if (productToSync) {

            let _products = productToSync;

            if (checked)
                _products.push({ id: product.id, imageUrl: webUrl.linodeObjectUrl + product.sku + '.jpg' });
            else
                _products = _products.filter(value => value.id !== product.id);

            setProductToSync(_products);
        }
    }

    const productSync = () => {
        if (productToSync && productToSync.length > 0) {

            let req = {
                ids: productToSync
            }

            props.syncProducts(req)
                .then((response) => {
                    response && setProductToSync([]);
                    response && onSearchClick();
                    response || setAlertMessage("Sync failed.");
                    response || setShowAlert(true);
                })
        }
        else {
            setAlertMessage("Please select product to sync.");
            setShowAlert(true);
        }
    }

    const allProductPublish = () => {
        if (productToSync && productToSync.length > 0) {
            updateProduct({
                ids: productToSync.map(value => value.id),
                published_status: true
            });
        }
        else {
            setAlertMessage("Please select product to publish.");
            setShowAlert(true);
        }
    }

    const allProductUnPublish = () => {
        if (productToSync && productToSync.length > 0) {
            updateProduct({
                ids: productToSync.map(value => value.id),
                published_status: false
            });
        }
        else {
            setAlertMessage("Please select product to un-publish.");
            setShowAlert(true);
        }
    }

    const isChecked = (id) => {
        if (productToSync) {
            return !!(productToSync.filter(value => value.id == id))[0];
        }
        else {
            return false;
        }
    }

    const onAllChecked = (checked) => {

        setIsAllChecked(checked);

        if (checked) {

            var products = props.products && props.products.product &&
                            props.products.product.map(value => ({ id: value.id, imageUrl: value.linodeObjectUrl + value.sku + '.jpg' }));
            setProductToSync(products);
        }
        else
            setProductToSync([]);
    }

    const productsData = () => {

        return (

            props.products && props.products.product && Array.isArray(props.products.product) ?
                props.products.product.map((value, index) => {

                    return (
                        <IonCard key={index} onClick={() => { onRowClick(index) }}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol className='product-img-col'>
                                        <IonImg className="product-img" src={value.image} alt=""></IonImg>
                                    </IonCol>
                                    <IonCol>
                                        <IonRow>
                                            <IonCol className='first-rw-size'>
                                                <IonLabel><strong> {value.title}</strong></IonLabel>
                                            </IonCol>
                                            <IonCol className='second-rw-size'>
                                                <IonRow>
                                                    <IonCol className='product-card-action'>
                                                        {
                                                            value.published_at ?
                                                                hasPermission('UnPublishProduct') &&
                                                                <IonIcon color='danger' onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowConfirm(true);
                                                                    setAlertMessage("Are you sure to un-publish?");
                                                                    setProductId(value.id);
                                                                }} icon={flashOffOutline} className='header-icon' />
                                                                :
                                                                hasPermission('PublishProduct') &&
                                                                <IonIcon onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    publishProduct(value.id);
                                                                }} icon={flashOutline} className='header-icon' />
                                                        }
                                                    </IonCol>
                                                    <IonCol className='product-card-action'>

                                                        {
                                                            hasPermission('UpdateProduct') &&
                                                            <IonIcon onClick={(e) => {
                                                                e.stopPropagation();
                                                                setProductId(value.id);
                                                                setProductDescription(value.description);
                                                                setSeoTitle(value.seo_title);
                                                                setSeoDescription(value.seo_description);
                                                                setShowUpdateModal(true);
                                                            }} icon={createOutline}
                                                                className='header-icon' />
                                                        }
                                                    </IonCol>
                                                    <IonCol className='product-card-action'>
                                                        <IonCheckbox className='product-chk'
                                                            onClick={e => { e.stopPropagation() }}
                                                            onIonChange={e => { e.stopPropagation(); onProductChecked(value, e.detail.checked); }}
                                                            checked={isChecked(value.id)}
                                                        />

                                                    </IonCol>
                                                </IonRow>

                                            </IonCol>
                                        </IonRow>
                                        {
                                            value.tags &&
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>Tags &nbsp;&nbsp;<strong>{value.tags.join(', ')}</strong></IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        }

                                        <IonRow>
                                            <IonCol size='4'>
                                                <IonLabel><strong>{value.quantity} in stock</strong></IonLabel>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <IonLabel><strong>{value.product_type}</strong></IonLabel>
                                            </IonCol>
                                            <IonCol size='3'>
                                                <IonLabel><strong>${value.price}</strong></IonLabel>
                                            </IonCol>
                                            <IonCol size='3'>
                                                <IonLabel><strong>{value.sku}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        {
                                            index === detailsToShowIndex &&
                                            <div>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>Barcode <strong>{value.barcode}</strong></IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>Published at <strong>{value.published_at}</strong></IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>Description <strong>{value.description}</strong></IonLabel>
                                                    </IonCol>
                                                </IonRow>

                                            </div>
                                        }
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Products' redirectTo={props.history.push}
                showProductSyncIcon={true} onProductSyncClick={productSync}
                uploadProductImg={true} onUploadProductImg={handleAddPhotoClick}
                publishProduct={true} onPublishProduct={allProductPublish} 
                unPublishProduct={true} onUnPublishProduct={allProductUnPublish} />

            <div className="pallet-body">
                <IonCard className='search-criteria'>
                    <IonCardHeader className='search-criteria-header'>
                        <IonCardTitle> Search Criteria</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className='search-field-section'>
                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel>Title</IonLabel>
                                <IonInput name='searchTitle'
                                    value={props.formData.searchTitle}
                                    onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}>
                                </IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Type</IonLabel>
                                <SingleSelect
                                    name='searchType'
                                    options={props.productTypes}
                                    optionValue='product_type_code'
                                    optionName='product_type_description'
                                    onChange={onFieldChange}
                                    value={props.formData.searchType} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Status</IonLabel>
                                <SingleSelect
                                    name='searchStatus'
                                    options={[{ key: 'published', value: 'Published' }, { key: 'unpublished', value: 'Un-Published' }, { key: 'any', value: 'Any' }]}
                                    optionValue={'key'}
                                    optionName={'value'}
                                    onChange={onFieldChange}
                                    value={props.formData.searchStatus} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel> Tag</IonLabel>
                                <IonInput name='searchTag'
                                    value={props.formData.searchTag}
                                    onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}>
                                </IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Barcode</IonLabel>
                                <IonInput name='searchBarcode'
                                    value={props.formData.searchBarcode}
                                    onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}>
                                </IonInput>
                            </IonCol>
                        </IonRow>

                    </IonCardContent>

                    <IonRow>
                        <IonCol sizeMd='2'>
                            <IonButton className='button action-btn' type='button' onClick={onSearchClick}>
                                Search
                                </IonButton>
                        </IonCol>
                        <IonCol sizeMd='2'>
                            <IonButton className='button action-btn' type='button' onClick={onClearClick}>
                                Clear
                                </IonButton>
                        </IonCol>
                        <IonCol sizeMd='2'>
                            <IonButton className='button action-btn' type='button' onClick={() => { props.history.push(webUrl.pallet) }}>
                                Back
                                </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCard>

                <IonCard className='search-result pallet-items'>
                    <IonCard className='pagging-card'>
                        <IonCardContent className='pad-0'>
                            <IonRow>
                                <IonCol size='5'>
                                    <IonIcon icon={caretBackOutline}
                                        disabled={pageNo <= 2}
                                        color={pageNo <= 2 ? 'medium' : 'primary'}
                                        className='header-icon'
                                        onClick={() => { pageNo > 2 && getProductData(pageNo - 2) }} />
                                </IonCol>
                                <IonCol size='.5'>
                                    <label>{pageNo - 1}</label>
                                </IonCol>
                                <IonCol size='.6'>
                                    <IonIcon
                                        icon={caretForwardOutline}
                                        className='header-icon'
                                        color={props.products && props.products.nextCursor ? 'primary' : 'medium'}
                                        onClick={() => {setIsAllChecked(false);  getProductData(pageNo) }} />
                                </IonCol>
                                <IonCol className='product-card-action self-center' size='5.4'>
                                    <IonCheckbox className='product-chk'
                                        onClick={e => { e.stopPropagation() }}
                                        onIonChange={e => { e.stopPropagation(); onAllChecked(e.detail.checked); }}
                                        checked={isAllChecked}
                                    />
                                </IonCol>
                            </IonRow>

                        </IonCardContent>
                    </IonCard>
                    <IonContent className='search-result-data order-search-result-data bg-grey'>
                        {productsData()}
                        {/* <IonInfiniteScroll threshold='100px' onIonInfinite={getProducts} disabled={props.products && props.products.product
                            && Array.isArray(props.products.product) && props.products.product.length === props.products.count}>
                            <IonInfiniteScrollContent loadingSpinner='bubbles' loadingText='Loading more data...' />
                        </IonInfiniteScroll> */}
                    </IonContent>
                </IonCard>
            </div>
            <AppAlert showAlert={showConfirm} headerText='Confirm' message={alertMessage} btnCancelText="Cancel" btnOkText="Yes" okClick={unPublish} cancelClick={() => { setShowConfirm(false); setProductId(0); }} />
            <input id="productPic" type="file" onChange={handleFileUpload} name='productPic' accept="image/*" ref={(ref) => setuploadPic(ref)} style={{ display: 'none' }} multiple />
            <ProductUpdateModal
                productDescription={productDescription}
                setProductDescription={setProductDescription}
                seoTitle={seoTitle}
                setSeoTitle={setSeoTitle}
                seoDescription={seoDescription}
                setSeoDescription={setSeoDescription}
                showModal={showUpdateModal}
                onDoneClick={onDoneClick}
                closeModal={closeModal} />
            <AppAlert showAlert={showAlert} headerText='Message' message={alertMessage} btnCancelText="OK" cancelClick={() => { setShowAlert(false) }} />

        </IonPage >
    );
};

const mapStateToProps = state => ({
    formData: state.product.formData,
    products: state.product.products,
    productTypes: state.product.productTypes
});

const mapDispatchToProps = dispatch => ({
    productAction: (data) => dispatch(productAction(data)),
    unmountProductAction: () => dispatch(unmountProductAction()),
    clearProductAction: () => dispatch(clearProductAction()),
    productTypeAction: () => dispatch(productTypeAction()),
    updateProductFormData: (data) => dispatch(updateProductFormData(data)),
    clearProductFormData: () => dispatch(clearProductFormData()),
    updateProduct: (data) => dispatch(updateProductAction(data)),
    updateProductDescription: (data) => dispatch(updateProductDescriptionAction(data)),
    syncProducts: (data) => dispatch(syncProductsAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product));

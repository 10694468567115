import { doGetRequest, doPostRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';
import { stringformat } from '../../shared/common';

export function addUpdateShipper(data) {
    return doPostRequest(apiUrl.addUpdateShipper, data);
}

export function deleteShipper(id) {
    return doGetRequest(stringformat(apiUrl.deleteShipper, [id]));
}

export function addUpdatePalletType(data) {
    return doPostRequest(apiUrl.addUpdatePalletType, data);
}

export function deletePalletType(id) {
    return doGetRequest(stringformat(apiUrl.deletePalletType, [id]));
}

export function addUpdateUsers(data) {
    return doPostRequest(apiUrl.addUpdateUsers, data);
}

export function deleteUser(id) {
    return doGetRequest(stringformat(apiUrl.deleteUser, [id]));
}

export function addUpdateCategory(data) {
    return doPostRequest(apiUrl.addUpdateCategory, data);
}

export function deleteCategory(id) {
    return doGetRequest(stringformat(apiUrl.deleteCategory, [id]));
}
import {
    barcodeScanApi,updateQty,validateStoreId, palletStatus, pallets, palletStore, palletCategory,
    palletItemsById, palletTypes, palletBuilders, savePallet,
    updatePallet, savePalletItem, deletePallet, deletePalletItem,
    updatePalletItem, palletShipper, getItemDescription,syncPrice, syncPriceStatus, getITODetails,getPoid,receivePO
} from './service';
import { showLoaderAction, stopLoaderAction } from '../user/action';

/**
 * Action types
 */
export const actionTypes = {
    'PALLET_STATUS_RECEIVED': 'PALLET_STATUS_RECEIVED',
    'PALLET_CATEGORY_RECEIVED': 'PALLET_CATEGORY_RECEIVED',
    'PALLET_STORE_RECEIVED': 'PALLET_STORE_RECEIVED',
    'PALLETS_RECEIVED': 'PALLETS_RECEIVED',
    'PALLETS_UNMOUNT': 'PALLETS_UNMOUNT',
    'PALLETS_CLEAR': 'PALLETS_CLEAR',
    'PALLET_ITEMS_RECEIVED': 'PALLET_ITEMS_RECEIVED',
    'PALLET_BUILDERS_RECEIVED': 'PALLET_BUILDERS_RECEIVED',
    'PALLET_TYPES_RECEIVED': 'PALLET_TYPES_RECEIVED',
    'PALLET_ADD_ITEM_TO_LIST': 'PALLET_ADD_ITEM_TO_LIST',
    'PALLET_ADD_UPDATE_UNMOUNT': 'PALLET_ADD_UPDATE_UNMOUNT',
    'PALLET_SHIPPER_RECEIVED': 'PALLET_SHIPPER_RECEIVED',
    'CLEAR_PALLET_FORM_DATA': 'CLEAR_PALLET_FORM_DATA',
    'PALLET_FORM_DATA': 'PALLET_FORM_DATA',
    'BARCODE_SCAN': 'BARCODE_SCAN',
    'BARCODE_SCAN_po': 'BARCODE_SCAN_po',
    'VALIDATE_STORE_ID': 'VALIDATE_STORE_ID',
    'PRICE_SYNC_STATUS': 'PRICE_SYNC_STATUS'
};

export const setbarcodescan = (payload) => ({
    type: actionTypes.BARCODE_SCAN,
    payload
});

export const getITODescriptionAction = (itoNumber) => {
    console.log(itoNumber,"uzzi data1")

    return (dispatch, getState) => {

        dispatch(showLoaderAction('barcodeScan'));

        console.log(itoNumber,"data2")
        return barcodeScanApi(itoNumber)

            .then((response) => {
                if(response.length){
                    dispatch(setbarcodescan(response));
                    return response;
                }

                return false;
            })
            .catch((error) => {
                
                dispatch(setbarcodescan());
                // dispatch(stopLoaderAction('getITODescription'));
                return false;
            });
    }
}


export const setbarcodescanpo = (payload) => ({
    type: actionTypes.BARCODE_SCAN_po,
    payload
});

export const getPODescriptionAction = (itoNumber) => {
    console.log(itoNumber,"data1")

    return (dispatch, getState) => {

        dispatch(showLoaderAction('barcodeScanPo'));

        console.log(itoNumber,"data2")
        return getPoid(itoNumber)

            .then((response) => {
                if(response.length){
                    dispatch(setbarcodescanpo(response));
                    return response;
                }

                return false;
            })
            .catch((error) => {
                
                dispatch(setbarcodescanpo());
                // dispatch(stopLoaderAction('getITODescription'));
                return false;
            });
    }
}


export const receivePOAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('receivePO'));

        return receivePO(data)
            .then((response) => {

                dispatch(stopLoaderAction('receivePO'));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('receivePO'));
                return false;
            });
    }
}

export const updateCartonQtyAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('updateQty'));

        return updateQty(data)
            .then((response) => {

                dispatch(stopLoaderAction('updateQty'));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('updateQty'));
                return false;
            });
    }
}

export const validateIdAction = (payload) => ({
    type: actionTypes.VALIDATE_STORE_ID,
    payload
});

export const validateStoreIdAction = (pallet_id,ito_id) => {

    return (dispatch, getState) => {

        dispatch(showLoaderAction('validateStoreId'));

        return validateStoreId(pallet_id,ito_id)
            .then((response) => {

                dispatch(stopLoaderAction('validateStoreId'));
                dispatch(validateIdAction(response));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('validateStoreId'));
                return false;
            });
    }
}

export const receivedPalletStatusAction = (payload) => ({
    type: actionTypes.PALLET_STATUS_RECEIVED,
    payload
});

export const palletStatusAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletStatus'));

        return palletStatus()
            .then((response) => {
                dispatch(stopLoaderAction('palletStatus'));
                dispatch(receivedPalletStatusAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletStatus'));
            });
    }
}

export const receivedPalletStoreAction = (payload) => ({
    type: actionTypes.PALLET_STORE_RECEIVED,
    payload
});

export const palletStoreAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletStore'));

        return palletStore()
            .then((response) => {
                dispatch(stopLoaderAction('palletStore'));
                dispatch(receivedPalletStoreAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletStore'));
            });
    }
}

export const receivedPalletCategoryAction = (payload) => ({
    type: actionTypes.PALLET_CATEGORY_RECEIVED,
    payload
});

export const palletCategoryAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletCategory'));

        return palletCategory()
            .then((response) => {
                dispatch(stopLoaderAction('palletCategory'));
                dispatch(receivedPalletCategoryAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletCategory'));
            });
    }
}

export const receivedPalletsAction = (payload) => ({
    type: actionTypes.PALLETS_RECEIVED,
    payload
});

export const palletsAction = (data) => {

    return (dispatch, getState) => {

        data && data.page < 2 && dispatch(showLoaderAction('pallets'));

        return pallets(data)
            .then((response) => {

                data && data.page < 2 && dispatch(stopLoaderAction('pallets'));

                if (data && data.page > 1) {
                    let state = Object.assign({}, getState());
                    response[0] = [...state.pallet.pallets[0], ...response[0]];
                }

                dispatch(receivedPalletsAction(response));
                return true;
            })
            .catch((error) => {

                data && data.page < 2 && dispatch(stopLoaderAction('pallets'));
                return false;
            });
    }
}

export const unmountPalletsAction = () => ({
    type: actionTypes.PALLETS_UNMOUNT
});

export const palletsMasterDataAction = () => {
    return (dispatch) => {
        dispatch(palletStoreAction());
        dispatch(palletCategoryAction());
        dispatch(palletStatusAction());
    }
};

export const clearPalletsAction = () => ({
    type: actionTypes.PALLETS_CLEAR
});


export const receivedPalletItemsAction = (payload) => ({
    type: actionTypes.PALLET_ITEMS_RECEIVED,
    payload
});

export const palletItemsAction = (id) => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletItems'));

        return palletItemsById(id)
            .then((response) => {

                dispatch(stopLoaderAction('palletItems'));
                dispatch(receivedPalletItemsAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletItems'));
            });
    }
}

export const receivedPalletTypesAction = (payload) => ({
    type: actionTypes.PALLET_TYPES_RECEIVED,
    payload
});

export const palletTypesAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletTypes'));

        return palletTypes()
            .then((response) => {

                dispatch(stopLoaderAction('palletTypes'));
                dispatch(receivedPalletTypesAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletTypes'));
            });
    }
}

export const receivedPalletBuildersAction = (payload) => ({
    type: actionTypes.PALLET_BUILDERS_RECEIVED,
    payload
});

export const palletBuildersAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletBuilders'));

        return palletBuilders()
            .then((response) => {

                dispatch(stopLoaderAction('palletBuilders'));
                dispatch(receivedPalletBuildersAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletBuilders'));
            });
    }
}

export const addUpdatePalletsMasterDataAction = () => {
    return (dispatch) => {
        dispatch(palletTypesAction());
        dispatch(palletBuildersAction());
    }
};

export const addItemToListAction = (payload) => ({
    type: actionTypes.PALLET_ADD_ITEM_TO_LIST,
    payload
});

export const unMountAddUpdateData = () => ({
    type: actionTypes.PALLET_ADD_UPDATE_UNMOUNT
});

export const savePalletAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('savePallet'));

        return savePallet(data)
            .then((response) => {

                dispatch(stopLoaderAction('savePallet'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('savePallet'));
                return false;
            });
    }
}

export const updatePalletAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('updatePallet'));

        return updatePallet(data)
            .then((response) => {

                dispatch(stopLoaderAction('updatePallet'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('updatePallet'));
                return false;
            });
    }
}

export const savePalletItemAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('savePalletItem'));

        return savePalletItem(data)
            .then((response) => {

                dispatch(stopLoaderAction('savePalletItem'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('savePalletItem'));
                return false;
            });
    }
}

export const deletePalletAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deletePallet'));

        return deletePallet(data)
            .then((response) => {

                dispatch(stopLoaderAction('deletePallet'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('deletePallet'));
                return false;
            });
    }
}

export const deletePalletItemAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deletePalletItem'));

        return deletePalletItem(data)
            .then((response) => {

                dispatch(stopLoaderAction('deletePalletItem'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('deletePalletItem'));
                return false;
            });
    }
}

export const updatePalletItemAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('updatePalletItem'));

        return updatePalletItem(data)
            .then((response) => {

                dispatch(stopLoaderAction('updatePalletItem'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('updatePalletItem'));
                return false;
            });
    }
}

export const receivedPalletShipperAction = (payload) => ({
    type: actionTypes.PALLET_SHIPPER_RECEIVED,
    payload
});

export const palletShipperAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletShipper'));

        return palletShipper()
            .then((response) => {

                dispatch(stopLoaderAction('palletShipper'));
                dispatch(receivedPalletShipperAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletShipper'));
            });
    }
}

export const updatePalletFormData = (payload) => ({
    type: actionTypes.PALLET_FORM_DATA,
    payload
});

export const clearFormData = (payload) => ({
    type: actionTypes.CLEAR_PALLET_FORM_DATA,
    payload
});

// export const getITODescriptionAction = (data) => {
//     console.log(data, "ekta data1")

//     return (dispatch, getState) => {

//         dispatch(showLoaderAction('getITODescription'));

//         console.log(data,"data2")
//         return getITODescription(data)

//             .then((response) => {


//                 return response;
//             })
//             .catch((error) => {

//                 // dispatch(stopLoaderAction('getITODescription'));
//                 return false;
//             });
//     }
// }

    // return () => {

    //     return getITODescription(barcode)
    //         .then((response) => {

    //             return response;
    //         })
    //         .catch((error) => {
    //             return false;
    //         });
    // }
    export const getItemDescriptionAction = (data) => {

        return () => {
    
            return getItemDescription(data)
                .then((response) => {
    
                    return response;
                })
                .catch((error) => {
                    return false;
                });
        }
    }

export const getITODetailsAction = (data) => {

    return () => {

        return getITODetails(data)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}

export const syncPriceAction = () => {

    return (dispatch) => {

        dispatch(setSyncStatus(true));

        return syncPrice()
            .then((response) => {

                dispatch(syncPriceStatusAction());
            })
            .catch((error) => {

                dispatch(setSyncStatus(false));
            });
    }
}

export const syncPriceStatusAction = () => {

    return (dispatch) => {

        return syncPriceStatus()
            .then((response) => {

                if (response) {
                    response = response.indexOf('False') > -1;
                    dispatch(setSyncStatus(!response));
                }
                else {
                    dispatch(setSyncStatus(false));
                }
            })
            .catch((error) => {

                dispatch(setSyncStatus(false));
            });
    }
}

export const setSyncStatus = (payload) => ({
    type: actionTypes.PRICE_SYNC_STATUS,
    payload
});
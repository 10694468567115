import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal
} from '@ionic/react';
import { SingleSelect } from '../single-select';
export const StatusModal = (props) => {
    return (

        <IonModal isOpen={ props.showModal } className='pallet-status-modal'>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Update Status</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol>
                                    <IonLabel> Status</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <SingleSelect
                                        name='status'
                                        options={ props.palletStatus }
                                        optionValue={ (localStorage.getItem('userType') === 'manager' || localStorage.getItem('userType') === 'admin'|| localStorage.getItem('userType') === 'buyer') ? 'status_name' : 'status' }
                                        optionName={ (localStorage.getItem('userType') === 'manager' || localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'buyer') ? 'status_name' : 'status' }
                                        value={ props.status }
                                        onChange={ props.onModalFieldChange } />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel> Changed By</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel><strong>&nbsp; { props.userName }</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.onDoneClick }>Done</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.closeModal }>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
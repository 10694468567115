import { doGetRequest, doPostRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';
import { stringformat } from '../../shared/common';

export function poItems(data) {
    return doPostRequest(apiUrl.getPO, data);
}

export function poItemsById(id) {
    return doGetRequest(stringformat(apiUrl.poItemsById, [id]));
}

export function poStatus() {
    return doGetRequest(apiUrl.getPOStatus);
}

export function poQuantity(poId, supplierSku) {
    return doGetRequest(stringformat(apiUrl.getPOItemQuantity, [poId, supplierSku]));
}

export function receivePO(data) {
    return doPostRequest(apiUrl.receivePO, data);
}

export function uploadPO(data) {
    return doPostRequest(apiUrl.uploadPO, data);
}

export function poStatusAndItemCount(poId) {
    return doGetRequest(stringformat(apiUrl.getPOStatusAndItemCount, [poId]));
}

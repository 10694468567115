import React from 'react';
import './index.css';
import {
    IonIcon, IonTitle, IonMenu, IonHeader, IonToolbar, IonContent, IonList, IonItem,
    IonLabel, IonButtons, IonMenuButton, IonRow, IonCol, IonMenuToggle
} from '@ionic/react';
import {
    addOutline, homeOutline, expand, power, walletOutline, callOutline, busOutline,
    personCircleOutline, albumsOutline, sync, fileTray, cloudUploadOutline, airplaneOutline,
    bookmarkOutline, peopleOutline, flashOutline, journalOutline, flashOffOutline, briefcaseOutline, reload
} from 'ionicons/icons';
import { webUrl } from '../../shared/constants';
import { hasPermission } from '../../shared/common';

export const AppHeader = (props) => {
    return (
        <>
            <IonMenu side="start" contentId="main-content">
                <IonHeader>
                    <IonToolbar translucent class='menu-toolbar'>
                        <IonTitle>Menu</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonMenuToggle>

                            <IonItem className='app-profile white-border'>
                                <IonIcon className='profile-logo' icon={personCircleOutline}></IonIcon>
                            </IonItem>
                            <IonItem className='app-profile white-border'>
                                <IonLabel className='logged-in-user'><strong>{localStorage.user}</strong></IonLabel>
                            </IonItem>
                            <IonItem className='app-profile logged-in-user-name'>
                                <IonLabel className='logged-in-user'>({localStorage.userName})<br />(V1.24)</IonLabel>
                            </IonItem>

                            <IonItem onClick={() => { props.redirectTo(webUrl.pallet) }}>
                                <IonIcon icon={walletOutline} slot="start"></IonIcon>
                                <IonLabel>Pallets</IonLabel>
                            </IonItem>
                            {/* <IonItem onClick={() => { props.redirectTo(webUrl.newpallet) }}>
                                <IonIcon icon={walletOutline} slot="start"></IonIcon>
                                <IonLabel>New Pallets</IonLabel>
                            </IonItem> */}
                            {/* <IonItem onClick={() => { props.redirectTo(webUrl.palletnew) }}>
                                <IonIcon icon={walletOutline} slot="start"></IonIcon>
                                <IonLabel>New Pallets</IonLabel>
                            </IonItem> */}
                            {/* <IonItem onClick={() => { props.redirectTo(webUrl.receivepallet) }}>
                                <IonIcon icon={walletOutline} slot="start"></IonIcon>
                                <IonLabel>Receive Pallet</IonLabel>
                            </IonItem> */}
                            {
                                <IonItem onClick={() => { props.redirectTo(webUrl.warehouse) }}>
                                    <IonIcon icon={briefcaseOutline} slot="start"></IonIcon>
                                    <IonLabel>Receive PO</IonLabel>
                                </IonItem>
                            }
                            {/*
                                hasPermission('Distribution') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.distribution) }}>
                                    <IonIcon icon={expand} slot="start"></IonIcon>
                                    <IonLabel>Distribution</IonLabel>
                                </IonItem>
                            */}
                            {
                                hasPermission('OrderReport') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.estore) }}>
                                    <IonIcon icon={albumsOutline} slot="start"></IonIcon>
                                    <IonLabel>Estore</IonLabel>
                                </IonItem>
                            }
                            {
                                hasPermission('Product') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.product) }}>
                                    <IonIcon icon={fileTray} slot="start"></IonIcon>
                                    <IonLabel>Product</IonLabel>
                                </IonItem>
                            }
                            {
                                hasPermission('ShipperMaster') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.shipper) }}>
                                    <IonIcon icon={airplaneOutline} slot="start"></IonIcon>
                                    <IonLabel>Shipper Master</IonLabel>
                                </IonItem>
                            }
                            {
                                hasPermission('PalletTypeMaster') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.palletType) }}>
                                    <IonIcon icon={bookmarkOutline} slot="start"></IonIcon>
                                    <IonLabel>Pallet Type Master</IonLabel>
                                </IonItem>
                            }
                            {
                                hasPermission('UserMaster') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.user) }}>
                                    <IonIcon icon={peopleOutline} slot="start"></IonIcon>
                                    <IonLabel>User Master</IonLabel>
                                </IonItem>
                            }
                            {
                                hasPermission('CategoryMaster') &&
                                <IonItem onClick={() => { props.redirectTo(webUrl.category) }}>
                                    <IonIcon icon={journalOutline} slot="start"></IonIcon>
                                    <IonLabel>Category Master</IonLabel>
                                </IonItem>
                            }
                            {/* {
                                <IonItem onClick={() => { props.redirectTo(webUrl.purchaseOrder) }}>
                                    <IonIcon icon={briefcaseOutline} slot="start"></IonIcon>
                                    <IonLabel>Purchase Order</IonLabel>
                                </IonItem>
                            } */}
                            {/* {
                                <IonItem onClick={() => { props.redirectTo(webUrl.receivePO) }}>
                                    <IonIcon icon={briefcaseOutline} slot="start"></IonIcon>
                                    <IonLabel>Receive PO</IonLabel>
                                </IonItem>
                            }
                            {
                                <IonItem onClick={() => { props.redirectTo(webUrl.receivePONew) }}>
                                    <IonIcon icon={briefcaseOutline} slot="start"></IonIcon>
                                    <IonLabel>Receive PO New</IonLabel>
                                </IonItem>
                            }
                            {
                                <IonItem onClick={() => { props.redirectTo(webUrl.receivePONewodoo) }}>
                                    <IonIcon icon={-} slot="start"></IonIcon>
                                    <IonLabel>Receive PO Odoo</IonLabel>
                                </IonItem>
                            } */}
                            <IonItem onClick={() => {
                                props.redirectTo(webUrl.signIn);
                                let userId = localStorage.getItem('user');
                                let userName = localStorage.getItem('userName');
                                localStorage.clear();
                                localStorage.setItem('user', userId);
                                localStorage.setItem('userName', userName);
                            }
                            }>
                                <IonIcon icon={power} slot="start"></IonIcon>
                                <IonLabel>Sign Out</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                    </IonList>
                </IonContent>
            </IonMenu>

            <div id="main-content">
                <IonHeader>
                    <IonToolbar class='menu-toolbar'>
                        <IonButtons slot="start">
                            <IonMenuButton class='menu-button'></IonMenuButton>
                        </IonButtons>
                        <IonRow>
                            <IonCol>
                                <IonTitle>{props.headerText}</IonTitle>
                            </IonCol>

                            <IonCol className='pallet-menu'>
                                {props.showAddPalletIcon && <IonIcon icon={addOutline} className='header-icon' onClick={() => { props.redirectTo(webUrl.addUpdatePallet) }} />}
                                {props.showBookingIcon && <IonIcon icon={callOutline} className='header-icon' onClick={() => { props.redirectTo(webUrl.palletBooking) }} />}
                                {props.showDispatchIcon && <IonIcon icon={busOutline} className='header-icon' onClick={() => { props.redirectTo(webUrl.palletDispatch) }} />}
                                {props.showSyncIcon && <IonIcon icon={sync} className={'header-icon' + (props.syncStatus ? ' image-spin' : '')} onClick={props.onSyncClick} />}
                                {props.publishProduct && <IonIcon icon={flashOutline} className='header-icon' onClick={props.onPublishProduct} />}
                                {props.unPublishProduct && <IonIcon icon={flashOffOutline} className='header-icon' onClick={props.onUnPublishProduct} />}
                                {props.uploadProductImg && <IonIcon icon={cloudUploadOutline} className='header-icon' onClick={props.onUploadProductImg} />}
                                {props.showProductSyncIcon && <IonIcon icon={sync} className={'header-icon' + (props.syncStatus ? ' image-spin' : '')} onClick={props.onProductSyncClick} />}
                                {props.showAdd && <IonIcon icon={addOutline} className='header-icon' onClick={props.onAddClick} />}
                                {props.reload && <IonIcon icon={reload} className='header-icon' onClick={props.onReloadClick} />}
                            </IonCol>
                        </IonRow>
                    </IonToolbar>
                </IonHeader>

            </div>
        </>
    )
}

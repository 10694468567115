import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonLabel, IonRow, IonCol, IonModal, IonInput
} from '@ionic/react';
export const MasterModal = (props) => {
    return (

        <IonModal isOpen={ props.showModal } onDidDismiss={ props.closeModal } className='master-modal'>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle> { props.id ? 'Update (' + props.id + ')' : 'Add New' }</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Name</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='name' value={ props.name } onIonChange={ (e) => { props.setName(e.target.value) } }></IonInput>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.onDoneClick }>Done</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.closeModal }>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}
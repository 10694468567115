import { doGetRequest, doPostRequest, doOdooPostRequest, doOdooGetRequest } from './../../shared/api/base-api';
import { apiUrl } from './../../shared/constants';
import { stringformat } from '../../shared/common';

export function getPoid(poid) {
    return doGetRequest(stringformat(apiUrl.getPoid, [poid]));
}

export function barcodeScanApi(ITOID) {
    return doGetRequest(stringformat(apiUrl.getIto, [ITOID]));
}

export function updateQty(data) {
    return doPostRequest(apiUrl.updateQty, data);
}

export function receivePO(data) {
    return doPostRequest(apiUrl.receivePO, data);
}

export function validateStoreId(pallet_id, ito_id) {
    return doGetRequest(stringformat(apiUrl.getvalidatestoreid, [pallet_id, ito_id]));
}

export function palletStatus() {

    let userType = localStorage.getItem('userType');
    let uri;

    if (userType === 'warehouse')
        uri = apiUrl.palletWarehouseStatus;
    else if (userType === 'store')
        uri = apiUrl.palletStoreStatus;
    else
        uri = apiUrl.palletStatus;

    return doGetRequest(uri);
}

export function palletCategory() {
    return doGetRequest(apiUrl.palletCategory);
}

export function palletStore() {
    return doGetRequest(apiUrl.palletStore);
}

export function pallets(data) {
    return doPostRequest(apiUrl.pallets, data);
}

export function palletItemsById(id) {
    return doGetRequest(stringformat(apiUrl.palletItemsById, [id]));
}

export function palletTypes() {
    return doGetRequest(apiUrl.palletTypes);
}

export function palletBuilders() {
    return doGetRequest(apiUrl.palletBuilders);
}

export function savePallet(data) {
    return doPostRequest(apiUrl.addPalletAndItems, data);
}

export function updatePallet(data) {
    return doPostRequest(apiUrl.addPalletAndItems, data);
}

export function savePalletItem(data) {
    return doPostRequest(apiUrl.addPalletItem, data);
}

export function deletePallet(id) {
    return doGetRequest(stringformat(apiUrl.deletePallet, [id]));
}

export function deletePalletItem(id) {
    return doGetRequest(stringformat(apiUrl.deletePalletItem, [id]));
}

export function updatePalletItem(data) {
    return doPostRequest(apiUrl.updatePalletItem, data);
}

export function palletShipper() {
    return doGetRequest(apiUrl.palletShipper);
}

export function palletByStatus(data) {
    return doPostRequest(apiUrl.palletByStatus, data);
}

export function updatePalletShippingStatus(data) {
    return doPostRequest(apiUrl.updatePalletShippingStatus, data);
}

export function getItemDescription(data) {
    return doOdooGetRequest(stringformat(apiUrl.getDescription, [data]));
}

// export function getITODescription(ITOID,barcode) {
//     return doGetRequest(stringformat(apiUrl.getIto, [ITOID,barcode]));
// }

export function getITODetails(data) {
    return doPostRequest(apiUrl.pallets, data);
}

export function syncPrice() {
    return doPostRequest(apiUrl.syncPrice, {});
}

export function syncPriceStatus() {
    return doGetRequest(apiUrl.syncPriceStatus);
}
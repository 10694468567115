import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonButton, IonLabel, IonRow, IonCol,
    IonInfiniteScroll, IonInfiniteScrollContent, IonContent, IonDatetime, IonRouterLink
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import { webUrl } from '../../shared/constants';
import './index.css';
import { ordersAction, unmountOrderAction, clearOrderAction, ordersCostAction } from './action';

const OrderReport = (props) => {

    const [formData, setFormData] = useState({});
    const [pageNo, setPageNo] = useState(1);

    useEffect(() => {

        getPallets();
        props.ordersCostAction();

        return () => {

            props.unmountOrderAction();
        }
    }, []);

    const getPallets = (e) => {

        getPalletData(pageNo, e);
    }

    const onFieldChange = (name, value) => {

        setFormData({ ...formData, [name]: value });
    }

    const getPalletData = (page = 1, event) => {

        let request = { page: page, ...formData };

        props.ordersAction(request).then(() => {

            event && event.target.complete();
        });;

        setPageNo(page + 1);
    }

    const onSearchClick = () => {

        props.clearOrderAction();
        getPalletData(1);
    }

    const onClearClick = () => {
        setFormData({});
        props.clearOrderAction();
        setPageNo(1);
        setTimeout(() => {
            setFormData({});
        }, 0);
    }

    const ordersData = () => {

        return (

            props.orders && Array.isArray(props.orders) && props.orders[0] && props.orders[0].orders && Array.isArray(props.orders[0].orders) ?
                props.orders[0].orders.map((value, index) => {

                    return (
                        <IonCard key={ index }>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel className='head-label'><strong> { value.order_number }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel><strong>{ value.processed_at }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol sizeMd='2'>
                                        <IonLabel><strong>${ value.total_price }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol sizeMd='2'>
                                        <IonRouterLink href={ value.order_status_url } target='_blank' ><strong> Order Status </strong></IonRouterLink>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Estore' redirectTo={ props.history.push } />

            <div className="pallet-body">

                <IonCard className='search-criteria order-search-criteria'>
                    <IonCardContent className='order-search-section'>
                        <IonRow>
                            <IonCol>
                                <IonCard className='summary-card'>
                                    <IonCardContent className='summary-content'>
                                        <IonRow className='center'>
                                            <IonLabel>Total orders today</IonLabel>
                                        </IonRow>
                                        <IonRow className='center'>
                                            <IonLabel><strong>{ props.ordersCost && props.ordersCost[0] && props.ordersCost[0].OrdersToday ? props.ordersCost[0].OrdersToday : 0 }</strong></IonLabel>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard className='summary-card'>
                                    <IonCardContent className='summary-content'>
                                        <IonRow className='center'>
                                            <IonLabel>Total sales today</IonLabel>
                                        </IonRow>
                                        <IonRow className='center'>
                                            <IonLabel><strong>${ props.ordersCost && props.ordersCost[1] && props.ordersCost[1].SalesToday ? props.ordersCost[1].SalesToday : 0 }</strong></IonLabel>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard className='summary-card'>
                                    <IonCardContent className='summary-content'>
                                        <IonRow className='center'>
                                            <IonLabel>Total orders this month</IonLabel>
                                        </IonRow>
                                        <IonRow className='center'>
                                            <IonLabel><strong>{ props.ordersCost && props.ordersCost[2] && props.ordersCost[2].OrdersThisMonth ? props.ordersCost[2].OrdersThisMonth : 0 }</strong></IonLabel>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            { <IonCol>
                                <IonCard className='summary-card'>
                                    <IonCardContent className='summary-content'>
                                        <IonRow className='center'>
                                            <IonLabel>Total sales this month</IonLabel>
                                        </IonRow>
                                        <IonRow className='center'>
                                            <IonLabel><strong>${ props.ordersCost && props.ordersCost[3] && props.ordersCost[3].SalesThisMonth ? props.ordersCost[3].SalesThisMonth.toFixed(2) : 0 }</strong></IonLabel>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol> }
                        </IonRow>
                    </IonCardContent>
                    <IonCardContent className='order-search-section order-flex-row'>
                        <IonRow>
                            <IonCol>
                                <IonLabel> Start Date</IonLabel>
                                <IonDatetime displayFormat='DD-MM-YYYY' value={ formData.startDate } onIonChange={ e => onFieldChange('startDate', e.detail.value) } />
                            </IonCol>
                            <IonCol>
                                <IonLabel> End Date</IonLabel>
                                <IonDatetime displayFormat='DD-MM-YYYY' value={ formData.endDate } onIonChange={ e => onFieldChange('endDate', e.detail.value) } />
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonButton className='button' type='button' onClick={ onSearchClick }>
                                    Search
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonButton className='button' type='button' onClick={ onClearClick }>
                                    Clear
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonButton className='button' type='button' onClick={ () => { props.history.push(webUrl.pallet) } }>
                                    Back
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>

                <IonCard className='search-result pallet-items'>
                    <IonContent className='search-result-data order-search-result-data bg-grey'>
                        { ordersData() }
                        <IonInfiniteScroll threshold='100px' onIonInfinite={ getPallets } disabled={ props.orders && props.orders[0] && props.orders[0].orders && Array.isArray(props.orders[0].orders) && props.orders[0].orders.length === props.orders[1].count }>
                            <IonInfiniteScrollContent loadingSpinner='bubbles' loadingText='Loading more data...' />
                        </IonInfiniteScroll>
                    </IonContent>
                </IonCard>
            </div>

        </IonPage >
    );
};

const mapStateToProps = state => ({
    formData: {},
    orders: state.order.orders,
    ordersCost: state.order.ordersCost
});

const mapDispatchToProps = dispatch => ({
    ordersAction: (data) => dispatch(ordersAction(data)),
    unmountOrderAction: () => dispatch(unmountOrderAction()),
    clearOrderAction: () => dispatch(clearOrderAction()),
    ordersCostAction: () => dispatch(ordersCostAction())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderReport));

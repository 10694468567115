import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonInput, IonLabel, IonRow, IonCol, IonCheckbox,
    IonInfiniteScroll, IonInfiniteScrollContent, IonContent, IonTextarea
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import '../pallet-booking/index.css';
import {
    palletsAction, palletItemsAction, unmountPalletsAction, clearPalletsAction,
    clearFormData, updatePalletFormData,savePalletAction
} from '../pallet/action'
import { SingleSelect } from '../../components/single-select';
import { AppAlert } from '../../components/app-alert';
import {
    palletBookingMasterDataAction, updatePalletShippingStatusAction,
    palletByStatusAction
} from '../pallet-booking/action';
import { webUrl } from '../../shared/constants';

const PalletDispatch = (props) => {

    const [detailsToShowIndex, setDetailsToShowIndex] = useState(undefined);
    const [itemDetailsToShowIndex, setItemDetailsToShowIndex] = useState(undefined);
    const [pageNo, setPageNo] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {

        props.palletBookingMasterDataAction();
        props.clearFormData({ palletIds: [] });
        getPallets();

        return () => {
            props.unmountPalletsAction();
            props.clearFormData({ palletIds: [] });
        }
    }, []);

    const getPallets = (e) => {

        getPalletData(pageNo, e);
    }

    const onFieldChange = (name, value) => {

        props.updateFormData({ [name]: value });
    }

    const getPalletData = (page = 1, event) => {

        let request = { page: page };
        request.status = 'Booked';

        if (props.formData && props.formData.searchStore)
            request.store_id = props.formData.searchStore;

        props.palletByStatusAction(request).then(() => {

            event && event.target.complete();
        });;

        setPageNo(page + 1);
    }

    const onSearchClick = () => {
        props.clearPalletsAction();
        setDetailsToShowIndex(undefined);
        setItemDetailsToShowIndex(undefined);
        getPalletData(1);
    }

    const onClearClick = () => {

        props.clearPalletsAction();
        setDetailsToShowIndex(undefined);
        setItemDetailsToShowIndex(undefined);
        setPageNo(1);
        props.clearFormData({ palletIds: [] });
    }

    const onRowClick = (index, id) => {

        if (index === detailsToShowIndex) {

            setDetailsToShowIndex(undefined);
        }
        else {

            props.palletItemsAction(id).then(() => {

                setDetailsToShowIndex(index);
            });
        }

        setItemDetailsToShowIndex(undefined);
    }

    const onItemRowClick = (index) => {
        index === itemDetailsToShowIndex
            ? setItemDetailsToShowIndex(undefined) :
            setItemDetailsToShowIndex(index);
    }

    const onDispatchClick = () => {
        if (!props.formData || !props.formData.conNumber || !props.formData.shipper || !props.formData.palletIds || props.formData.palletIds.length <= 0) {
            setAlertMessage("Please enter con. number, select shipper and pallet to dispatch");
            setShowAlert(true);
            return;
        }
    
        let request = {}
    
        if (props.formData) {
            if (props.formData.palletIds) request.id = props.formData.palletIds.join(',');
            if (props.formData.shipper) request.shipper = props.formData.shipper;
            if (props.formData.conNumber) request.con_number = props.formData.conNumber;
            request.status = 'Dispatched';
            request.user = props.userName;
        }
    
        // Dispatch pallet
        props.updatePalletShippingStatusAction(request)
            .then((response) => {
                if (response) {
                    setAlertMessage("Dispatched successfully!!!");
    
                    // Call updateitopalletinfo to update Odoo with pallet data
                    let palletData = {
                        id: request.id,
                        status: request.status,            // User performing the booking
                        con_number: request.con_number,
                        last_status_changed_by: request.user

                    };
                    // console.log("requesttttttt",request);
                    // console.log("formdataaaaaaaaaaa",props.formData);
                    props.savePalletAction(palletData)
                        .then(() => {
                            console.log('Pallet data sent to Odoo successfully');
                        })
                        .catch((error) => {
                            console.error('Error sending pallet data to Odoo:', error);
                        });
    
                    setItemDetailsToShowIndex(undefined);
                    setDetailsToShowIndex(undefined);
                    props.clearPalletsAction();
                    onSearchClick(1);
                    props.clearFormData({ palletIds: [], searchStore: request.store_id });
                    props.updateFormData({ callApi: true });
                } else {
                    setAlertMessage("Dispatching failed!!!");
                }
                setShowAlert(true);
            });
    };
    

    const onPalletChecked = (pallet, checked) => {

        if (props.formData && props.formData.palletIds) {

            let _palletIds = props.formData.palletIds;
            let total = 0;
            let typeToUpdate = {}

            if (checked)
                _palletIds.push(pallet.id);
            else
                _palletIds = props.formData.palletIds.filter(value => value !== pallet.id);

            typeToUpdate.palletIds = _palletIds;

            if (pallet.pallet_type) {

                if (pallet.pallet_type.toLowerCase() === 'chep') {

                    typeToUpdate.chep = checked
                        ? (props.formData.chep ? props.formData.chep + 1 : 1)
                        : (props.formData.chep ? props.formData.chep - 1 : '');
                    checked
                        ? total++
                        : total--;
                }
                else if (pallet.pallet_type.toLowerCase() === 'loscam') {

                    typeToUpdate.loscam = checked
                        ? (props.formData.loscam ? props.formData.loscam + 1 : 1)
                        : (props.formData.loscam ? props.formData.loscam - 1 : '');

                    checked
                        ? total++
                        : total--;
                }
                else if (pallet.pallet_type.toLowerCase() === 'plain') {

                    typeToUpdate.plain = checked
                        ? (props.formData.plain ? props.formData.plain + 1 : 1)
                        : (props.formData.plain ? props.formData.plain - 1 : '');

                    checked
                        ? total++
                        : total--;
                }

                typeToUpdate.total = props.formData.total ? props.formData.total + total : total;
            }

            if (pallet.weight && !isNaN(pallet.weight)) {

                typeToUpdate.weight = checked
                    ? (props.formData.weight ? props.formData.weight + parseInt(pallet.weight) : parseInt(pallet.weight))
                    : (props.formData.weight ? props.formData.weight - parseInt(pallet.weight) : '');
            }

            props.updateFormData(typeToUpdate)
        }
    }

    const palletItemsData = () => {
        return (

            props.palletItems && props.palletItems.length > 0 ?
                props.palletItems.map((value, index) => {

                    return (
                        <IonCard key={ index } onClick={ (e) => { e.stopPropagation(); onItemRowClick(index) } }>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel className='head-label-small'><strong>{ value.barcode }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel className='head-label-small'> Qty <strong>{ value.quantity }</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel><strong>{ value.description }</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow >
                                    <IonCol>
                                        <IonLabel> ITO no. <strong>{ value.ito }</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                {
                                    index === itemDetailsToShowIndex &&
                                    <>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Item Id <strong>{ value.id }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Outer <strong>{ value.outer }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Inner <strong>{ value.inner }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received <strong>{ value.received_count }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Added by <strong>{ value.added_by }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </>

                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    const palletData = () => {

        return (

            props.pallets && Array.isArray(props.pallets) && props.pallets[0] && Array.isArray(props.pallets[0]) ?
                props.pallets[0].map((value, index) => {

                    return (
                        <IonCard key={ index } onClick={ () => { onRowClick(index, value.id) } }>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol sizeMd='2'>
                                        <IonLabel className='head-label'><strong> { value.id }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol className='card-action'>
                                        <IonCheckbox className='card-chk'
                                            onClick={ e => { e.stopPropagation() } }
                                            onIonChange={ e => { e.stopPropagation(); onPalletChecked(value, e.detail.checked); } } />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeMd='2'>
                                        <IonLabel><strong>{ value.store_name }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel><strong>{ value.status }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel><strong>{ value.freight_company }</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>

                                    </IonCol>
                                </IonRow>

                                {
                                    index === detailsToShowIndex &&
                                    <div>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Freight Company <strong>{ value.freight_company }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Category <strong>{ value.category }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Supplier <strong>{ value.supplier }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Pallet Type <strong>{ value.pallet_type }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Weight <strong>{ value.weight }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Wrapped on <strong>{ value.wrapped_date }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Sent on <strong>{ value.sent_date }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received on <strong>{ value.received_date }</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonCard className='pallet-items'>
                                            <IonCardHeader>
                                                <IonCardTitle>
                                                    Pallet Items
                                            </IonCardTitle>
                                            </IonCardHeader>

                                            <IonCardContent className='pallet-items'>
                                                { palletItemsData() }
                                            </IonCardContent>
                                        </IonCard>
                                    </div>

                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Pallet Dispatch' redirectTo={ props.history.push } />

            <div className="pallet-body">

                <IonCard className='search-criteria booking-search-criteria'>
                    <IonCardContent className='booking-search-section'>
                        <IonRow>
                            <IonCol sizeMd='3'>
                                <IonLabel> Store</IonLabel>
                                <SingleSelect
                                    name='searchStore'
                                    options={ props.palletStore }
                                    optionValue='id'
                                    optionName='store_name'
                                    onChange={ onFieldChange }
                                    value={ props.formData.searchStore } />
                            </IonCol>
                            <IonCol sizeMd='2'>
                                <IonButton className='' type='button' onClick={ onSearchClick }>
                                    Search
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd='2'>
                                <IonButton className='' type='button' onClick={ onClearClick }>
                                    Clear
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                    <IonCardContent className='booking-search-section booking-flex-row'>
                        <IonRow>
                            <IonCol>
                                <IonLabel> Shippers</IonLabel>
                                <SingleSelect
                                    name='shipper'
                                    options={ props.palletShipper }
                                    optionValue='shipper_name'
                                    optionName='shipper_name'
                                    onChange={ onFieldChange }
                                    value={ props.formData.shipper } />
                            </IonCol>
                            <IonCol>
                                <IonLabel> Con. Number</IonLabel>
                                <IonInput name='conNumber'
                                    value={ props.formData.conNumber }
                                    onIonChange={ (e) => { onFieldChange(e.target.name, e.target.value) } }>
                                </IonInput>
                            </IonCol>
                            <IonCol>
                                <IonLabel> Total Weight</IonLabel>
                                <IonInput disabled={ true } value={ props.formData.weight } />
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonLabel> Chep</IonLabel>
                                <IonInput disabled={ true } value={ props.formData.chep } />
                            </IonCol>
                            <IonCol>
                                <IonLabel> Loscam</IonLabel>
                                <IonInput disabled={ true } value={ props.formData.loscam } />
                            </IonCol>
                            <IonCol>
                                <IonLabel> Plain</IonLabel>
                                <IonInput disabled={ true } value={ props.formData.plain } />
                            </IonCol>
                            <IonCol>
                                <IonLabel> Total</IonLabel>
                                <IonInput disabled={ true } value={ props.formData.total } />
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                    <IonCardContent className='booking-search-section'>
                        <IonRow>
                            <IonCol sizeMd='4' size='6'>
                                <IonLabel> Selected Pallets</IonLabel>
                                <IonTextarea disabled={ true } value={ props.formData.palletIds && props.formData.palletIds.join(', ') } />
                            </IonCol>
                            <IonCol sizeMd='2' size='3.7'>
                                <IonButton className='button booking-btn' type='button' onClick={ onDispatchClick }>
                                    Dispatch
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd='2' size='2.3'>
                                <IonButton className='button booking-btn' type='button' onClick={ () => { props.history.push(webUrl.pallet) } }>
                                    Back
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>

                <IonCard className='search-result pallet-items booking-search-result'>
                    <IonContent className='search-result-data booking-search-result-data bg-grey'>
                        { palletData() }
                        <IonInfiniteScroll threshold='100px' onIonInfinite={ getPallets } disabled={ props.pallets && props.pallets[0] && Array.isArray(props.pallets[0]) && props.pallets[0].length === props.pallets[1][0].count }>
                            <IonInfiniteScrollContent loadingSpinner='bubbles' loadingText='Loading more data...' />
                        </IonInfiniteScroll>
                    </IonContent>
                </IonCard>
            </div>

            <AppAlert showAlert={ showAlert } headerText='Message' message={ alertMessage } btnCancelText="OK" cancelClick={ () => { setShowAlert(false) } } />

        </IonPage>
    );
};

const mapStateToProps = state => ({
    palletShipper: state.pallet.palletShipper,
    palletStore: state.pallet.palletStore,
    pallets: state.pallet.pallets,
    palletItems: state.pallet.palletItems,
    formData: state.pallet.formData,
    userName: localStorage.getItem('userName')
});

const mapDispatchToProps = dispatch => ({
    palletsAction: (data) => dispatch(palletsAction(data)),
    palletItemsAction: (id) => dispatch(palletItemsAction(id)),
    palletBookingMasterDataAction: () => dispatch(palletBookingMasterDataAction()),
    unmountPalletsAction: () => dispatch(unmountPalletsAction()),
    clearPalletsAction: () => dispatch(clearPalletsAction()),
    updateFormData: (data) => dispatch(updatePalletFormData(data)),
    clearFormData: (data) => dispatch(clearFormData(data)),
    updatePalletShippingStatusAction: (data) => dispatch(updatePalletShippingStatusAction(data)),
    palletByStatusAction: (data) => dispatch(palletByStatusAction(data)),
    savePalletAction: (data) => dispatch(savePalletAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PalletDispatch));

import React from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonInput, IonLabel, IonRow, IonCol, IonModal
} from '@ionic/react';
import { getVariance } from '../../shared/common';

export const ReceiveItemModal = (props) => {

    const onChange = (e) => {

        props.onModalFieldChange(e.target.name, e.target.value);
    }

    return (

        <IonModal isOpen={ props.showModal } className='receive-modal'>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Receive Item</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol>
                                    <IonLabel> Quantity</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel><strong>&nbsp; { props.modal.quantity }</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol class='v-center'>
                                    <IonLabel> Received Count</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput name='receivedCount' value={ props.modal.receivedCount } onIonChange={ onChange }></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel> Variance</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel><strong>&nbsp; { getVariance(props.modal.quantity, props.modal.receivedCount) }</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.onDoneClick }>Done</IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={ props.closeModal }>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>

    )
}